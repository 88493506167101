import { Fragment, useEffect, useState } from 'react';
import {
    useTranslate,
    Button,
    useNotify, useRefresh,
    useGetManyReference
} from 'react-admin';
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import { Link } from '@material-ui/icons';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { HandleError, ViewTitle } from '../../components';
import PodUserAssociationForm from './PodUserAssociationForm';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        display: 'flex',
    },
    spacer: {
        flex: 1,
    },
    buttonMarginLeft: {
        marginLeft: 10
    },
    flexBox: {
        width: 'auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    inputMarginLeft: {
        marginLeft: 20
    },
    border: {
        border: '1px solid #6b489d'
    }
}));


const GlobalAssociateUserToPod = (props) => {

    const translate = useTranslate();
    const [showDialog, setShowDialog] = useState(false);
    // Initialize podDefaultValue as an empty array
    const [podDefaultValue, setPodDefaultValue] = useState([]);
    const notify = useNotify();
    const refresh = useRefresh();
    const roomId = props.id;

    const { data, total, isLoading } = useGetManyReference("pods", {
        target: "room",
        id: props.id,
        filter: { 'type': 'user' },
        //pagination: { page, perPage },
        sort: { field: 'id', order: 'ASC' }
    });



    useEffect(() => {
        // Use useEffect to set the initialValues once data changes
        if (data) {
            const initialValues = data.map((pod) => ({
                podId: pod.id,
                podName: pod.name,
            }));
            setPodDefaultValue(initialValues);
        }
    }, [data]);



    const handleSubmit = async (formData) => {
        // Convert the form data to the desired format
        const transformedData = Object.values(formData).map((pod) => {
            return { id: pod.podId, name: pod.podName, users: pod.association };
        });

        // Use 'transformedData' array for API call 
        const options = {
            headers: {},
        };
        const token = localStorage.getItem('token');
        options.headers.Authorization = `Bearer ${token}`;

        try {
            const res = await axios.post(`/api/v1/classrooms/${roomId}/associate`, transformedData, options);
            if (res.status !== 200) {
                notify(translate('app.pods.associate.errorMessage'), { type: 'error' });
            }
            else {
                notify(translate('app.pods.associate.successMessage'), { type: 'success' });
                handleCloseClick();
                refresh();
            };
        } catch (error) {
            HandleError(error, notify);
        }
    };

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    if (isLoading) {
        return <div>Loading...</div>;
    };


    return (
        <Fragment>
            <Button label={translate('app.pods.associate.button')} onClick={handleClick}><Link /></Button>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label={translate('app.pods.associate.title')}
            >
                <DialogTitle> <ViewTitle label={translate('app.pods.associate.title')} icon={Link} /></DialogTitle>
                <DialogContent>
                    <div style={{ display: 'flex' }}>
                        <div style={{ margin: '10px' }}>
                            <PodUserAssociationForm
                                roomId={roomId}
                                data={data}
                                handleSubmit={handleSubmit}
                            />
                        </div>
                    </div>

                </DialogContent>
            </Dialog>
        </Fragment >
    );
};

export default GlobalAssociateUserToPod;

