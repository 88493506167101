import { Loading } from 'react-admin';
import Loadable from 'react-loadable';

const VolumeList = Loadable({ loader: () => import('./volumeList'), loading: Loading });
const VolumeCreate = Loadable({ loader: () => import('./volumeCreate'), loading: Loading });
const VolumeShow = Loadable({ loader: () => import('./volumeShow'), loading: Loading });
export default { 
  list: VolumeList,
  create: VolumeCreate,
  show: VolumeShow,
};