import { Show, useGetOne, useTranslate } from "react-admin";
import PodShow from "./podShow";
import { useParams } from 'react-router-dom';
import BasicBreadcrumbs from "../../components/breadcrumb";

const PodShowGeneric = ({ resourceType }) => {
    const translate = useTranslate();
    const { id, podId } = useParams();
    
    const { data: mainData, isLoading: isLoadingMain, error: errorMain } = useGetOne(resourceType, { id }, { enabled: resourceType !== 'myclassroom' });

    const { data: podData, isLoading: isLoadingPod, error: errorPod } = useGetOne('pods', { id: podId });

    if ((isLoadingMain && resourceType !== 'myclassroom') || isLoadingPod) return <div>{translate('app.LoadingMessage')}</div>;
    if ((errorMain && resourceType !== 'myclassroom') || errorPod) return <div>{translate('app.ErrorMessage')}</div>;

    return (
        <>
            <BasicBreadcrumbs name={resourceType !== 'myclassroom' ? mainData?.name : ''} name2={podData.name} />
            <Show id={podId} resource="pods" redirect={false}>
                <PodShow id={podId} inClassroom='yes' />
            </Show>
        </>
    );
};

export default PodShowGeneric;
