import { Loading } from 'react-admin';
import Loadable from 'react-loadable';

const FileList = Loadable({ loader: () => import('./fileList'), loading: Loading });
const FileCreate = Loadable({ loader: () => import('./fileCreate'), loading: Loading });
const FileDelete = Loadable({ loader: () => import('./fileDelete'), loading: Loading });
const DirectoryShow = Loadable({ loader: () => import('./directoryShow'), loading: Loading });

export default { 
  list: FileList,
  create: FileCreate,
  delete: FileDelete,
  show: DirectoryShow
};