import axios from 'axios';
import { useState } from 'react';
import { useTranslate, useSafeSetState, useRedirect } from 'ra-core';
import { Field, Form } from 'react-final-form';
import {
  Button, CardActions, CircularProgress, TextField, Typography,
  Card, CardContent
} from '@material-ui/core';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { CustomTheme } from '../custom';

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundImage: 'url(/background-login.jpg)',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    height: '1px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  card: {
    minWidth: 300,
    maxWidth: 400,
    marginTop: '6em',
  },
  form: {
    margin: theme.spacing(1),
    // add responsive behavior
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.5),
    },
  },
  input: {
    marginTop: theme.spacing(2),
    //width: '100%', // make input full width
    padding: '10px', // Adjust padding to prevent overlap    

    '& .MuiInputBase-input': {
      height: 'auto', // Adjust the height as needed
      padding: '12px', // Add padding to the actual input element
    },
  },
  button: {

  },
  title: {
    marginTop: 10
  },
  successMessage: {
    marginTop: 10
  },
  errorMessage: {
    marginTop: 10,
    color: 'red'
  },
  errorMessageDetails: {
    marginTop: 10,
    color: 'black'
  }
}),
  { name: 'RaResetPassword' }
);

const Input = ({
  meta: { touched, error }, // eslint-disable-line react/prop-types
  input: inputProps, // eslint-disable-line react/prop-types
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);


const ResetPassword = (props) => {
  const classes = useStyles(props);
  const [loading, setLoading] = useSafeSetState(false);
  const translate = useTranslate();
  const redirect = useRedirect();
  const [requestSent, setRequestSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const redirectToLogin = () => {
    redirect('/login');
  }

  const submit = async (values) => {
    try {
      setLoading(true);
      const data = { username: values.username };
      const options = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      await axios.post('/api/v1/auth/reset-password', data, options);
      setLoading(false);
      setRequestSent(true);
    }
    catch (error) {
      setLoading(false);
      if (error.response && error.response.data && error.response.data.message) {
        if (error.response.data.detail) {
          const detailedMessage = `${error.response.data.message} : ${error.response.data.detail.errorMessage}`;
          setErrorMessage(detailedMessage);
        } else {
          const message = error.response.data.message;
          setErrorMessage(message);
        }
      } else {
        const defaultError = typeof error === 'string' ? error : typeof error === 'undefined' || !error.message ? 'ra.auth.sign_in_error' : error.message;
        setErrorMessage(defaultError);
      }
    }
  };

  const required = (value) => (value ? undefined : translate('ra.validation.required'));

  const renderForm = (props) => {
    return (
      <Card className={classes.card}>
        <Form
          onSubmit={submit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Typography align='center' color='primary' variant='h6' className={classes.title}>{translate('app.resetPassword.title')}</Typography>
              <div className={classes.form}>
                <div className={classes.input}>
                  <Field
                    autoFocus
                    id="username"
                    name="username"
                    component={Input}
                    label={translate('ra.auth.username')}
                    disabled={loading}
                    validate={required}
                    InputProps={{ // Props passed to the Input element
                      className: classes.input,
                      style: { // You can also use inline styles here
                        height: '2em',
                        padding: '10px',
                        marginTop: '20px'
                      },
                    }}
                  />
                </div>
              </div>
              <CardActions>
                <Button fullWidth={true} variant="contained" type="submit" color="primary" disabled={loading} className={classes.button}>
                  {loading && (
                    <CircularProgress
                      className={classes.icon}
                      size={18}
                      thickness={2}
                    />
                  )}
                  {translate('app.resetPassword.resetButton')}
                </Button>
              </CardActions>
            </form>
          )}
        />
      </Card>
    );
  }

  const renderSuccess = () => {
    return (
      <Card className={classes.card}>
        <CardContent>
          <Typography align='center' color='primary' variant='h5' className={classes.successMessage}>{translate('app.resetPassword.success2')}</Typography>
        </CardContent>
        <CardActions>
          <Button fullWidth={true} onClick={redirectToLogin} variant="contained" color="primary" className={classes.button}>
            {translate('app.resetPassword.redirectToLoginButton')}
          </Button>
        </CardActions>
      </Card>
    );
  }

  const renderError = () => {
    return (
      <Card className={classes.card}>
        <CardContent>
          <Typography align='center' variant='h5' className={classes.errorMessage}>{translate('app.resetPassword.error1')}</Typography>
          <Typography align='center' variant='h5' className={classes.errorMessage}>{translate('app.resetPassword.error2')}</Typography>
          <Typography align='center' variant='h6' className={classes.errorMessageDetails}>{errorMessage}</Typography>
        </CardContent>
        <CardActions>
          <Button fullWidth={true} onClick={redirectToLogin} variant="contained" color="primary" className={classes.button}>
            {translate('app.resetPassword.redirectToLoginButton')}
          </Button>
        </CardActions>
      </Card>
    );
  }

  let content = <div></div>;
  if (errorMessage) content = renderError();
  else if (requestSent) content = renderSuccess();
  else content = renderForm();

  return (
    <ThemeProvider theme={CustomTheme}>
      <div className={classes.main} >
        {content}
      </div>
    </ThemeProvider>
  );
};

export default ResetPassword;