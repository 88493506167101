import { Loading } from 'react-admin';
import Loadable from 'react-loadable';

const InstanceTemplatesList = Loadable({ loader: () => import('./instanceTemplatesList'), loading: Loading });
const InstanceTemplateShow = Loadable({ loader: () => import('./instanceTemplateShow'), loading: Loading });
const InstanceTemplateCreate = Loadable({ loader: () => import('./instanceTemplateCreate'), loading: Loading });
const InstanceTemplateEdit = Loadable({ loader: () => import('./instanceTemplateEdit'), loading: Loading });

export default { 
  list: InstanceTemplatesList,
  show: InstanceTemplateShow,
  create: InstanceTemplateCreate,
  edit: InstanceTemplateEdit
};