
function formatBytes(bytes, decimals = 2, prefix) {

    if (prefix==="MB") {
        if (!+bytes) return '0 Bytes'

        const g = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    
        const i = Math.floor(Math.log(bytes) / Math.log(g))
    
        return `${parseFloat((bytes / Math.pow(g, i)).toFixed(dm))} ${sizes[i]}`
    } else {
        if (!+bytes) return '0 Bytes'

        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    
        const i = Math.floor(Math.log(bytes) / Math.log(k))
    
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }

}

export default formatBytes;