import React, { useEffect, useState, Fragment } from 'react';
import {
    useDataProvider, useNotify, useRedirect,
    Create, SimpleForm, TextInput, BooleanInput,
    ReferenceArrayInput, AutocompleteArrayInput,
    SelectInput, useTranslate,
    Toolbar,
    SaveButton,
    NumberInput,
    useRefresh
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { SectionField, ViewTitle } from '../../components';
import { makeStyles } from '@mui/styles';
import { Storage } from '@material-ui/icons';
import { Box } from '@mui/material';
import BasicBreadcrumbs from '../../components/breadcrumb';
import { Description } from '@mui/icons-material';


const useStyles = makeStyles((theme) => ({
    toolbar: {
        display: 'flex',
    },
    spacer: {
        flex: 1,
    },
    buttonMarginLeft: {
        marginLeft: 10
    },
    flexBox: {
        width: 'auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    inputMarginLeft: {
        marginLeft: 20
    },
    description: {
        //width: 'auto',
        '& .MuiInputBase-root': {
            paddingBottom: '50px',  // Adjust padding inside the input box
            paddingRight: '20px',  // Adjust padding inside the input box
            boxSizing: 'content-box',
            width: '100%'
        },
    }
}));


const GroupSelectInput = ({ source, label }) => {
    const dataProvider = useDataProvider();
    const [choices, setChoices] = useState([]);

    useEffect(() => {
        dataProvider.getList('users/groups', {
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'name', order: 'ASC' },
            filter: {}
        })
            .then(({ data }) => {
                setChoices(data);
            })
            .catch(error => {
                console.error('Error fetching groups:', error);
            });
    }, [dataProvider]);

    return (
        <SelectInput source={source} label={label} choices={choices} optionText="name" optionValue="id" />
    );
};


const MyToolbar = () => (
    <Toolbar>
        <SaveButton alwaysEnable redirect="show" />
    </Toolbar>
);



const TrainingTemplateClone = () => {
    const { id } = useParams(); // Original template ID to clone
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const [initialValues, setInitialValues] = useState(null);
    const classes = useStyles();
    const translate = useTranslate();
    const optionRenderer = choice => `${choice.name} #${choice.snapshotVersion}`;
    const filterToQuery = searchText => ({ name: `${searchText}` });

    const onSuccess = (data) => {
        notify(translate('app.trainingTemplates.create.successMessage'), { type: 'success' })
        redirect('show', '/trainingTemplates', data.id);
        refresh();
    };

    const onError = (error) => {

        if (error.body) {
            {
                error.body.detail ? notify(`${error.body.message} : ${error.body.detail.errorMessage}`, { type: 'error' }) :
                    notify(`${error.body.message}`, { type: 'error' })
            };
        }
        else {
            notify('failed', { type: 'failed' });
        }
    };

    useEffect(() => {
        dataProvider.getOne('trainingTemplates', { id })
            .then(({ data }) => {

                // Remove unwanted fields from initialValues
                const { createdAt, updatedAt, updatedBy, id, ...filteredData } = data;

                // Pre-fill initial values with incremented step/version and optional changes to the name
                setInitialValues({
                    ...filteredData,
                    step: data.step + 1,
                    name: `${data.name}`,
                });

            })
            .catch(error => {
                console.error("Error fetching template:", error);
            });
    }, [id, dataProvider]);


    if (!initialValues) return null;

    return (
        <Fragment>
            <BasicBreadcrumbs name={initialValues.name} />
            <ViewTitle label={translate('app.trainingTemplates.clone.title')} icon={Storage} />

            <Create resource="trainingTemplates" mutationOptions={{ onSuccess, onError }}>
                <SimpleForm defaultValues={initialValues} toolbar={<MyToolbar />} redirect="show">
                    <SectionField label={translate('app.trainingTemplates.create.data')} icon={Description} />
                    <Box className={classes.flexBox}>
                        <TextInput variant='outlined' source='name' style={{ minWidth: 200 }} />
                        <BooleanInput variant='outlined' source='production' className={classes.inputMarginLeft} />
                    </Box>
                    <Box className={classes.flexBox}>
                        <TextInput variant='outlined' source='description' className={classes.description} style={{ minWidth: 200 }} multiline />
                    </Box>

                    <Box className={classes.flexBox}>
                        <NumberInput variant='outlined' source='step' readOnly />
                        <SelectInput variant='outlined' source='language' choices={[
                            { id: 'en', name: 'app.users.languages.en' },
                            { id: 'fr', name: 'app.users.languages.fr' },
                        ]} defaultValue='en' className={classes.inputMarginLeft} />
                    </Box>

                    <SectionField label={translate('app.trainingTemplates.edit.commonPodInstance')} icon={Description} />
                    <ReferenceArrayInput
                        source="commonPod"
                        reference="instanceTemplates"
                        label={translate('app.instanceTemplates.title')}
                        filter={{ podType: 'common' }}
                    >
                        <AutocompleteArrayInput label={translate('app.instanceTemplates.title')} optionText={optionRenderer}
                            filterToQuery={filterToQuery}
                        />
                    </ReferenceArrayInput>

                    <SectionField label={translate('app.trainingTemplates.edit.userPodInstance')} icon={Description} />
                    <ReferenceArrayInput
                        source="userPod"
                        reference="instanceTemplates"
                        label={translate('app.instanceTemplates.title')}
                        filter={{ podType: 'user' }}
                    >
                        <AutocompleteArrayInput label={translate('app.instanceTemplates.title')} optionText={optionRenderer}
                            filterToQuery={filterToQuery}
                        />
                    </ReferenceArrayInput>
                    <GroupSelectInput source="group" label="Select NAS Group" />
                </SimpleForm>
            </Create>
        </Fragment>
    );
};

export default TrainingTemplateClone;
