import { Loading } from 'react-admin';
import Loadable from 'react-loadable';

const ImageList = Loadable({ loader: () => import('./imageList'), loading: Loading });
const BaseImageCreate = Loadable({ loader: () => import('./baseImageCreate'), loading: Loading });
const ImageEdit = Loadable({ loader: () => import('./imageEdit'), loading: Loading });

export default { 
  list: ImageList,
  create: BaseImageCreate,
  edit: ImageEdit
};