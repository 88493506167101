import axios from "axios";
import Button from '@material-ui/core/Button';
import { Confirm, useGetIdentity, useRecordContext } from 'react-admin';
import {
    useNotify, useRedirect, useRefresh, useTranslate
} from 'react-admin';
import { useState } from "react";
import { Clear } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import io from 'socket.io-client';
import { SectionField } from "../../components";

const BackendURL = process.env.REACT_APP_PROGRESS_URL; // Replace with your backend URL

const ClearClassroom = () => {

    const [open, setOpen] = useState(false);
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const record = useRecordContext();
    const translate = useTranslate();
    const [showProgressDialog, setShowProgressDialog] = useState(false); // New dialog state
    const [progressData, setProgressData] = useState({ progress: 0, statusText: '' });
    const handleDialogClose = () => setOpen(false);
    const handleClick = () => {
        setOpen(true);
    };

    let title = `${translate('app.classrooms.clear.title')}`;

    if (record && record.name) {
        title = `${translate('app.classrooms.clear.title')} ${record.name}`;
    } 

    const [deployments, setDeployments] = useState({}); // Store deployment state for each tab

    const { data: identity, isLoading, error } = useGetIdentity();

    const { id: userId } = identity;

    //let socket; // Declare the socket variable outside the function
    const [socket, setSocket] = useState(null); // State to hold the socket reference
    const handleShowProgressDialog = async () => {
        if (!userId) {
            // If userId is not available, return early
            return;
        };

        const nsocket = io(BackendURL, {
            query: { userId }, // Pass the userId as a query parameter
        });

        // Create a promise to wait for the WebSocket connection to be established
        const socketConnected = new Promise((resolve) => {
            nsocket.on('connect', () => {
                console.log('WebSocket connection established.');
                resolve();
            });
        });

        // Wait for the WebSocket connection to be established
        await socketConnected;

        nsocket.on('connect', () => {
            console.log('WebSocket connection established.');
        });

        nsocket.on('disconnect', () => {
            console.log('WebSocket connection closed.');
        });

        nsocket.emit('joinRoom', userId);

        // Create a variable to track the received progress updates
        let receivedProgressCount = 0;
        //const totalProgressCount = 100;

        // Event handler for 'deploymentProgress' event from the backend
        nsocket.on('clearClassroom', ({ userId, progress, statusText, trainingId, status }) => {
            // Update the progress and statusText in the component state
            setProgressData({ progress, statusText });

            // Open the progress dialog when the event is received
            setShowProgressDialog(true);

            const updatedDeployments = { ...deployments };
            updatedDeployments[userId] = { progress, statusText, status };
            setDeployments(updatedDeployments);

            // Increment the received progress count
            receivedProgressCount++;

            // If all progress updates have been received, disconnect the socket
            /*if (receivedProgressCount === totalProgressCount) {
                socket.disconnect();
            }*/
        });
        setSocket(nsocket); // Store the socket reference in state

        // Disconnect the socket when the function is done
        //socket.disconnect();
    };

    const handleCloseDialog = () => {
        console.log('disconnect');
        setShowProgressDialog(false);

        // Disconnect the socket when the dialog is closed
        try {
            if (socket && socket.connected) {
                socket.disconnect();
                console.log('WebSocket connection disconnected.');
            }
            setShowProgressDialog(false);
        } catch (error) {
            console.error('Error disconnecting WebSocket:', error);
        }
    };

    const handleConfirm = async () => {
        console.log("Open progress bar");
        setOpen(false);

        await handleShowProgressDialog();

        // Open the progress dialog initially
        setShowProgressDialog(true);

        try {
            const classroomId = record.id;
            const options = {
                headers: {},
            };

            const data = "";
            const token = localStorage.getItem('token');
            options.headers.Authorization = `Bearer ${token}`;

            const res = await axios.post(`/api/v1/classrooms/${classroomId}/clear`, data, options);

            if (res.status !== 200) {
                notify(translate('app.classrooms.clear.errorMessage'), { type: 'info' });
                redirect('/classrooms');
                refresh();
            }
            else {
                notify(translate('app.classrooms.clear.successMessage'), { type: 'success' })
                redirect('/classrooms');
                refresh();
            };
        }
        catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                notify(`${error.response.data.message} : ${error.response.data.detail.errorMessage}`, { type: 'error' });
                redirect('/classrooms');
                refresh();
            }

            else throw new Error(error);
        };
    };

    if (isLoading) return <div>{translate('app.LoadingMessage')}</div>;

    return (
        <>
            <Button color="primary" size='small' onClick={handleClick}><Clear />{translate('app.classrooms.clear.button')}</Button>
            <Confirm
                isOpen={open}
                title={title}
                content={translate('app.classrooms.clear.message')}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
            {/* Render your progress bar or other UI elements */}
            {/* New Dialog to show progress and status */}
            {Object.keys(deployments).map((userId) => (
                <Dialog
                    key={userId}
                    fullWidth
                    open={true} // Show dialog based on deployment state
                    onClose={() => {
                        handleCloseDialog();
                        const updatedDeployments = { ...deployments };
                        delete updatedDeployments[userId];
                        setDeployments(updatedDeployments);
                    }}
                    aria-label="Clear classroom progress"
                >
                    <DialogTitle>
                        <SectionField label='Clear classroom progress' icon={Clear} />
                    </DialogTitle>
                    <DialogContent>
                        <h1>Progress: {deployments[userId].progress}%</h1>
                        <h1>Status: {deployments[userId].status}</h1>
                        <h1>Step: {deployments[userId].statusText}</h1>
                    </DialogContent>
                </Dialog>
            ))}
        </>
    )
}

export default ClearClassroom;