import axios from "axios";
import Button from '@material-ui/core/Button';
import { useGetIdentity, useNotify, useRecordContext, useTranslate } from "react-admin";
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';

const BecomeUser = (props) => {
    const record = useRecordContext();
    const translate = useTranslate();
    const notify = useNotify();

    const { identity, isLoading: identityLoading } = useGetIdentity();
    if (identityLoading || !identity) return <>{translate('app.loadingMessage')}</>;
    const idAdmin = identity.id;

    const switchUser = async () => {

        if (!record.enabled) {
            notify(translate('app.users.become.notEnabledMessage'), {type: 'error' });
            //throw new Error(translate('app.users.become.notEnabledMessage'));
            return null;
        }
        try {
            const data = {
                adminId: idAdmin,
                userId: record.id
            };
            const options = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            const token = localStorage.getItem('token');
            options.headers.Authorization = `Bearer ${token}`;
            const res = await axios.post('/api/v1/auth/switch-user', data, options);
            if (res.data && res.data.token) {
                localStorage.setItem('token', res.data.token);
                localStorage.setItem('tokenAdmin', token);
                window.location.href = "/";
            }
            else notify(translate('app.users.become.noTokenFoundMessage'), {type: 'error' });
        }
        catch (error) {
            if (error.response && error.response.data && error.response.data.message) 
            notify(error.response.data.message, {type: 'error' });
            else notify(error, {type: 'error' });
        }
    }

    return (
        <Button color="primary" onClick={switchUser}><SupervisedUserCircleIcon />{translate('app.users.become.title')}</Button>
    )
}
export default BecomeUser;