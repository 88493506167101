import { useEffect } from 'react';
import { Login, resolveBrowserLocale, useSetLocale } from 'react-admin';
import CustomLoginForm from './customLoginForm';

const CustomLogin = (props) => {
  const setLocale = useSetLocale();

  const styles = {
    '.RaLogin-avatar': {
      backgroundPosition: 'center',
      height: 60,
      mx:5,
      background: 'url(/logo.png)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain'
    },
    '.RaLogin-icon': {
      display: 'none'
    }
  }


  useEffect(() => {
    const browserLocale = resolveBrowserLocale();
    console.log(`Detected browser locale : ${browserLocale}`);
    setLocale(browserLocale);
  }, [setLocale]);

  return (
    <Login backgroundImage='/background-login.jpg' sx={styles} {...props} children={< CustomLoginForm />} />
  )
};

export default CustomLogin;