const frenchCustomMessages = {
    resources: {
        users: {
            name: "Utilisateurs",
            fields: {
                email: "Nom d'utilisateur",
            }
        },
        admin: {
            name: 'Admin'
        },
        Design: {
            name: 'Design'
        },
        myworkspace: {
            name: 'Mon espace de travail'
        },
        images: {
            name: 'Sources'
        },
        classrooms: {
            name: "Salles de classe"
        },
        pods: {
            name: "Pods"
        },
        flavors: {
            name: "Flavors"
        },
        workspaces: {
            name: "Espaces de travail"
        },
        trainingTemplates: {
            name: "Modèles de formation"
        },
        instanceTemplates: {
            name: "Modèles d'instance"
        },
        networks: {
            name: "Réseaux"
        },
        routers: {
            name: "Routeurs"
        },
        vms: {
            name: 'Instances'
        },
        trainings: {
            name: "Formations"
        },
        volumes: {
            name: "Volumes"
        },
        snapshots: {
            name: "Snapshot"
        },
        myclassroom: {
            name: "Ma salle de classe"
        },
        mypod: {
            name: 'Mon Pod'
        },
        securityGroup: {
            name: 'Security Groups'
        },
        files: {
            name: 'Fichiers'
        },
        tasks: {
            name: 'Tâches'
        },
        hypervisors: {
            name: 'Hyperviseurs'
        }
    },
    app: {
        resetPassword: {
            title: "Réinitialiser le mot de passe",
            resetButton: 'Réinitialiser le mot de passe',
            redirectToLoginButton: "Aller à la page de connexion",
            success: 'Le mot de passe a été modifié avec succès.',
            success2: 'Vous recevrez sous peu un mail contenant le lien pour réinitialiser votre mot de passe.',
            error1: "Oups ! Quelque chose s'est mal passé ...",
            error2: 'Veuillez réessayer plus tard',
            confirmPassword: 'Confirmer le mot de passe',
            newPassword: 'Nouveau mot de passe',
            validation: 'Le mot de passe ne correspond pas.',
            passwordValidation: 'Le mot de passe doit contenir au moins 1 lettre et 1 chiffre.',
            username: 'Email'
        },
        buttons: {
            back: 'Retour',
            save: 'Sauvegarder',
            update: "Mettre à jour",
            create: 'Créer',
            send: "Envoyer",
            delete: 'Suppprimer',
            deploy: "Déployer"
        },
        misc: {
            createdAt: 'Création',
            updatedAt: 'Mis à jour',
            createdBy: 'Par',
            updatedBy: "Par",
            infosTitle: "Infos"
        },
        general: {
            title: 'General',
        },
        users: {
            titles: {
                list: 'Utilisateurs',
                create: 'Créer un utilisateur',
                edit: "Modifier l'utilisateur",
                details: "Détails de l'utilisateur"
            },
            search: {
                name: 'Rechercher par mail'
            },
            list: {
                firstname: 'Prénom',
                lastname: 'Nom',
                email: 'Email',
                enabled: 'Activé',
                password: 'Mot de passe',
                language: 'Langue'
            },
            sections: {
                credentials: "Informations d'identification",
                identity: 'Identité',
                rights: 'Droits'
            },
            roles: {
                admin: 'Administrateur',
                designer: "Designer",
                trainer: "Formateur",
                trainee: "Stagiaire",
                title: "Rôles"
            },
            languages: {
                en: 'Anglais',
                fr: 'Français',
            },
            become: {
                notEnabledMessage: 'Utilisateur non activé',
                noTokenFoundMessage: 'Aucun token trouvé',
                title: 'Devenir'
            },
            create: {
                successMessage: 'Utilisateur créé',
                errorMessage: 'Échec',
                title: 'Créer un utilisateur'
            },
            edit: {
                successMessage: 'Utilisateur mis à jour',
                errorMessage: 'Échec',
                title: 'Créer un utilisateur'
            },
            delete: {
                successMessage: 'Utilisateur supprimé',
                errorMessage: "Erreur lors de la suppression d'un utilisateur",
                title: 'Supprimer un utilisateur',
                confirmMessage: 'Êtes-vous sûr de vouloir supprimer cet utilisateur ?',
                button: 'Supprimer'
            },
            myprofile: 'Mon profil'
        },
        workspaces: {
            name: 'Nom',
            description: 'Description',
            number: 'Numéro',
            locator: 'Locator',
            firstname: 'Prénom',
            lastname: 'Nom',
            trainer: 'Formateur',
            numberOfVMs: 'Nombre de Vms',
            email: 'Email',
            titles: {
                myworkspace: 'Mon espace de travail',
                workspace: 'Espace de travail',
            },
            myworkspace: {
                noWorkspaceMessage: "Aucun espace de travail n'a été trouvé"
            },
            title: 'Espace de travail'
        },
        classrooms: {
            name: 'Nom',
            description: 'Description',
            number: 'Numéro',
            locator: 'Locator',
            firstname: 'Prénom',
            lastname: 'Nom',
            trainer: 'Formateur',
            numberOfVMs: 'Nombre de Vms',
            email: 'Email',
            noClassroomMessage: "It appears that you have not been assigned to any classrooms. Please contact support for assistance.",
            noClassroomTitle: "No Classroom Found",
            create: {
                successMessage: 'Salle de classe créée',
                errorMessage: 'Échec',
                title: 'Créer une salle de classe'
            },
            edit: {
                successMessage: "Mise à jour de la salle de classe",
                errorMessage: "Échec de la mise à jour de la salle de classe",
                title: 'Mise à jour de la salle de classe',
                data: 'Données',
                commonPod: 'Pod commun',
                userPod: 'Pod utilisateur',
                tags: 'Tags'
            },
            delete: {
                successMessage: 'Salle de Classe supprimée',
                errorMessage: 'Erreur lors de la supression de la salle de classe',
                title: 'Supprimer la salle de classe',
                confirmMessage: 'Êtes-vous sûr de vouloir supprimer la salle de classe ?',
                button: 'Supprimer'
            },
            clear: {
                successMessage: 'La salle de classe a été vidée',
                errorMessage: "Impossible de supprimer le contenu de la salle de classe",
                title: 'Supprimer le contenu de la salle de classe',
                message: 'Êtes-vous sûr de vouloir supprimer le contenu de la salle de classe ?',
                button: 'Nettoyer'
            },
            myclassroom: {
                errorMessage: 'Aucune classe trouvée'
            },
            inUsed: "En cours d'utilisation",
            trainees: 'Stagiaires',
            title: 'Salles de classe',
        },
        pods: {
            common: 'Pod commun',
            user: 'Pod utilisateur',
            guestOS: 'OS invité',
            ipAddress: 'Addresse Ip',
            instances: 'Instances',
            networks: 'Réseaux',
            network: 'Réseau',
            routers: "Routeurs",
            volumes: "Volumes",
            size: 'Taille',
            vms: 'Vms',
            trainees: 'stagiaires',
            status: 'Statut',
            name: 'Nom',
            type: 'type',
            description: 'Description',
            show: 'Afficher',
            number: 'Numéro',
            locator: 'Locator',
            firstname: 'Prénom',
            lastname: 'Nom',
            noPodMessage: "Aucun pod n'est attribué à votre compte. Veuillez contacter le support pour obtenir de l'aide.",
            associate: {
                successMessage: 'Associer un utilisateur à un pod',
                errorMessage: "Impossible d'associer un pod à un utilisateur",
                title: "Associer un utilisateur à un pod",
                message: 'Êtes-vous sûr de vouloir dissocier cet utilisateur ?',
                button: "Associer",
                data: 'Donnée',
                user: 'Utilisateur'
            },
            disassociate: {
                successMessage: 'Utilisateur dissocié du pod',
                errorMessage: "Impossible de dissocier le pod de l'utilisateur",
                title: "Dissocier l'utilisateur du pod",
                message: 'Êtes-vous sûr de vouloir dissocier cet utilisateur ?',
                button: "Dissocier",
                data: 'Donnée',
            },
            delete: {
                successMessage: 'Pod supprimé',
                errorMessage: 'Impossible de supprimer le pod',
                title: 'Supprimer ',
                confirmMessage: 'Êtes-vous sûr de vouloir supprimer ce pod ?',
                button: 'Supprimer'
            },
            start: {
                successMessage: 'VMs démarrée(s)',
                errorMessage: ' Impossible de démarrer une ou plusieurs VMs',
                title: 'Démarrer Toute les VMs de la class',
                message: 'Démarrage des VMs ',
                button: 'Démarrer',
                data: 'donnée',
                buttonInWorkspace: 'Démarrer toutes les VMs'
            },
            stop: {
                title: 'Arréter toutes les VMs',
                message: 'Etes-vous sûr de vouloir éteindre toutes les VMs?',
                button: 'Arréter',
                data: 'Données',
                buttonInWorkspace: 'Arrêter toutes les VMs'
            },

        },
        trainingTemplates: {
            deploy: {
                successMessage: 'Salle de classe déployée',
                errorMessage: 'Impossible de déployer la salle de classe',
                title: "Déployer le modèle de formation",
                message: 'Êtes-vous sûr de vouloir déployer cette salle de classe ?',
                button: "Déployer",
                data: 'Donnée',
                buttonInWorkspace: 'Déployer un Training Template'
            },
            deployUserPod: {
                successMessage: 'Pod utilisateur déployé',
                errorMessage: 'Impossible de déployer un pod utilisateur',
                title: 'Déployer un pod utilisateur',
                message: 'Combien de pods utilisateur souhaitez-vous déployer ?',
                button: 'Déployer un pod utilisateur',
                data: 'Données',
                podMax: 'Nombre de pods'
            },
            deployUserResources: {
                button: 'Déployer une resource utilisateur',
            },
            clone: {
                title: 'Clone Training Template',
            },
            list: {
                name: 'Nom',
                description: 'Description',
                language: 'Langue',
                production: 'Production',
                createdAt: 'Date de création',
                updatedAt: 'Date de mise à jour',
                createdBy: 'Créé par',
                updatedBy: "Mis à jour par",
                step: 'Etape',
            },
            deployCommonResources: {
                successMessage: 'Les resources communes ont été déployées',
                errorMessage: 'Impossible de deployer les resources communes',
                title: 'Déployer les resources communes',
                message: 'Données',
                button: 'Déployer les resources communes',
                data: 'Données',
            },
            create: {
                successMessage: 'Succès',
                errorMessage: 'Echec',
                title: "Créer un modèle de formation",
                data: 'Donnée',
                commonPod: "Pod commun",
                userPod: "Pod utilisateur",
                commonPodInstance: "Instances du pod commun",
                userPodInstance: 'Instances du pod utilisateur',
                tags: 'Tags'
            },
            edit: {
                successMessage: 'Succès',
                errorMessage: 'Echec',
                title: "Modifier le modèle de formation",
                data: 'Données',
                commonPod: "Pod commun",
                userPod: "Pod utilisateur",
                commonPodInstance: "Instances du pod commun",
                userPodInstance: 'Instances du pod utilisateur',
                tags: 'Tags'
            },
            replace: {
                button: 'Remplacer',
            },
            delete: {
                successMessage: 'Modèle de formation supprimé',
                errorMessage: 'Erreur lors de la suppression du modèle de formation',
                title: 'Suppression du modèle de formation',
                confirmMessage: 'Etes-vous sûr de vouloir supprimer ce modèle de formation ?',
                button: 'Suppression'
            },
            export: {
                exportFailed: 'Error exporting data',
                exportSuccessful: 'Export successful',
            },
            title: 'Modèle de formation'
        },
        flavors: {
            name: 'Nom',
            type: 'type',
            description: 'Description',
            create: {
                successMessage: 'Flavor créé',
                errorMessage: 'Échec',
                title: 'Créer un flavor'
            },
            ram: {
                title: 'Ram (MB)'
            },
            disk: {
                title: "Disque (GB)"
            },
            buttons: {
                deleteConfirmButton: 'Vous ne pourrez pas récupérer cet enregistrement. Êtes-vous sûr de vouloir le supprimer?',
                deleteButtonTitle: 'Supprimer le flavor'
            }
        },
        images: {
            format: 'Format',
            size: "Taille",
            status: 'Statut',
            name: 'Nom',
            convert: ' Convertir OVA en QCOW2',
            convertTitle: 'Convertir OVA',
            createdBy: 'Créé par',
            create: {
                title: 'Créer une image',
                filename: 'Fichier'
            },
            edit: {
                title: "Modifier l'image"
            },
            delete: {
                successMessage: 'Image supprimé',
                errorMessage: `Erreur lors de la suppression de l'image`,
                title: `Suppression de l'image`,
                confirmMessage: `Est-ce que vous êtes sûr de vouloir supprimer l'image`,
                button: 'Suppression'
            },
            task: {
                successMessage: 'Image supprimé',
                deleted: "Image supprimé",
                created: "L'image a été créé avec succès",
                uploading: "Téléchargement de l'image en cours",
                initialized: 'Initialisation en cours ',
                done: 'Exécuté',
                error: 'Erreur'
            }
        },
        instanceTemplates: {
            deploy: {
                successMessage: "Modèle d'instance déployé",
                errorMessage: "Erreur de déploiement du modèle d'instance",
                title: "Déploiement du modèle d'instance",
                message: 'Êtes-vous sûr de vouloir déployer cette instance ?',
                button: "Déployer",
                data: 'Donnée',
                classroom: "salle de classe",
                image: 'image',
                boot: "Média",
                instanceTemplate: "Modèle d'instance",
                selectPodMessage: 'Please select a Pod',
                partialFailureMessage: 'Failed Pod are : ',
                podColumn: 'Pod',
                statusColumn: 'Status',
                statusDialogTitle: 'Task status',
                closeButton: 'Close',
                errorMessageColumn: 'Error message'
            },
            create: {
                successMessage: "Modèle d'instance créé",
                errorMessage: "Erreur lors de la création du modèle d'instance",
                data: 'Données',
                imageName: 'Nom',
                pod: 'pod',
                vm: 'vm',
                title: "Créer un modèle d'instance",
                UseExistingVolumes: 'Utiliser les volumes existants',
                ipAddress: 'ipAddress',
                macAddress: 'macAddress',
            },
            delete: {
                successMessage: "Le modèle d'instance est supprimé",
                errorMessage: "Erreur de suppression du modèle d'instance",
                title: "Supprimer le modèle d'instance",
                confirmMessage: 'Êtes-vous sûr de vouloir supprimer ce modèle ?',
                button: "Supprimer",
                volumeSnapshotsMessage: 'Supprimer les volumes'
            },
            bulkDelete: {
                successMessage: "Modèles d'instances supprimés",
                errorMessage: "Erreur lors de la suppression des modèles d'instances",
                title: "Supprimer les modèles d'instances",
                confirmMessage: 'Êtes-vous sûr de vouloir supprimer ces modèles ?',
                button: 'Supprimer'
            },
            edit: {
                title: "Modifier le modèle d'instance",
                successMessage: "Mise à jour du modèle d'instance",
                errorMessage: "Erreur de mise à jour du modèle d'instance",
            },
            list: {
                title: "Modèles d'instance",
                status: 'Statut',
                volumeSnapshots: 'VolumeSnapshots',
                versioning: 'Version',
                name: 'Nom',
                language: 'Langue',
                production: 'Production',
                createdAt: 'Date de création',
                updatedAt: 'Date de mise à jour',
                createdBy: 'Créé par',
                updatedBy: "Mis à jour par",
            },
            replace: {
                replace: "Remplacer ou supprimer un modèle d'instance dans un ou plusieurs modèles de formation",
                title: "Remplacer ou supprimer le modèle d'instance",
                title1: 'Remplacer ou supprimer'
            },
            addToTrainingTemplate: {
                button: 'Ajouter à un modèle de formation',
                successMessage: "Le modèle d'instance a été ajouté au modèle de formation",
                errorMessage: "Erreur lors de l'ajout du modèle d'instance",
                title: "Ajouter un modèle d'instance au modèle de formation",
                message: "Êtes-vous sûr de vouloir ajouter un modèle d'instance à un modèle de formation ?",
                data: 'Données',
                classroom: "classe",
                trainingTemplate: 'Modèle de formation',
                image: 'image',
                boot: "Démarrer"
            },
            addInstanceTemplate: {
                button: "Ajouter un modèle d'instance",
            },
            export: {
                exportFailed: 'Error exporting data',
                exportSuccessful: 'Export successful',
            },
            title: "Modèle d'instance"
        },
        networks: {
            status: 'Statut',
            name: 'Nom',
            surname: 'Surnom',
            cidr: 'cidr',
            description: 'Description',
            ports: {
                enable: "Activer",
                disable: "Désactiver",
                connect: 'Connecter',
                disconnect: 'Déconnecter',
                successMessage: 'Port mis à jour avec succès',
                errorMessage: "Erreur de mise à jour du port",
                state: 'Statut',
                action: "Action",
                allowAddressPairs: "Paires d'adresses autorisées",
                removeAddressPairs: "Paires d'adresses supprimées",
                addressPairs: "Paires d'adresses",
                allowSuccessMessage: "Ajouter des paires d'adresses",
                allowErrorMessage: "Erreur lors de l'ajout de paires d'adresses",
                removeSuccessMessage: "Supprimer les paires d'adresses",
                removeErrorMessage: "Erreur lors de la suppression des paires d'adresses",
                updateDnsName: "Update DNS Name",
                dnsNameUpdated: "DNS Name updated successfully",
                dnsNameUpdateError: "Error updating DNS Name",
                dnsName: "DNS Name",
                fqdn: 'FQDN',
                cancel: "Cancel",
                submit: "Submit"
            },
        },
        routers: {
            status: 'Statut',
            name: 'Nom',
            surname: 'surname',
            cidr: 'cidr',
            description: 'Description',
        },
        securityGroups: {
            create: {
                successMessage: 'Groupe de sécurité créé',
                errorMessage: "Erreur lors de la création d'un groupe de sécurité",
                data: 'Donnée',
                pod: 'pod',
                title: "Création d'un groupe de sécurité",
                button: 'Créer',
            },
        },
        securityGroupRules: {
            name: 'Règles des groupes de sécurité',
            protocol: 'Protocole IP',
            delete: {
                successMessage: 'Règle du groupe de sécurité supprimée',
                errorMessage: "Erreur lors de la suppression d'une règle de groupe de sécurité",
                title: 'Supprimer',
                confirmMessage: 'Êtes-vous sûr de vouloir supprimer cette règle ?',
                button: 'supprimer'
            },
            create: {
                successMessage: "Création d'une règle de groupe de sécurité",
                errorMessage: "Erreur lors de la création d'une règle de groupe de sécurité",
                data: 'Données',
                pod: 'pod',
                title: "Création d'une règle de groupe de sécurité",
                button: 'Ajouter une règle',
            },
        },
        vms: {
            status: 'Statut',
            name: 'Nom',
            type: 'type',
            description: 'Description',
            guestOS: 'OS client',
            hideError: "Masquer l'erreur",
            showError: "Afficher l'erreur",
            networks: 'Networks',
            language: 'Langue',
            task: 'Tâche',
            create: {
                successMessage: 'Instance créée',
                errorMessage: "Erreur lors de la création de l'instance",
                data: 'Données',
                imageName: 'Nom',
                pod: 'pod',
                title: 'Créer une instance',
                button: 'Créer',
                disk: "Disque",
                resource: "Ressource(s)",
                mediadevice: 'Média',
                flavor: "Flavor",
                network: "Réseau",
                boot: "Démarrage",
                bootFromVolume: "Démarrage à partir d'un volume",
                image: 'Image',
                template: "Modèle",
                ipAddress: 'ipAddress',
                emptyVolumes: "Créer un volume vide (GB)"
            },
            show: {
                status: 'Statut',
                ipAddress: 'ipAddress',
                error: "Erreur",
                visibility: "visibilité",
                title: "Instance",
                attachedTo: "Attaché à",
                interface: 'Interfaces',
                mediaDevice: 'media Device'
            },
            delete: {
                successMessage: 'Instance supprimée',
                errorMessage: "Erreur de suppression de l'instance ",
                title: 'Supprimer',
                confirmMessage: 'Êtes-vous sûr de vouloir supprimer cette instance ?',
                button: 'Supprimer'
            },
            edit: {
                successMessage: 'Instance mis à jour',
                errorMessage: "Erreur lors de la mise à jour de l'Instance ",
                title: 'Editer cette instance',
                button: 'Editer'
            },
            action: {
                start: 'Démarrer',
                stop: "Arrêter",
                suspend: "Suspendre",
                resume: "Reprendre",
                reboot: "Redémarrer",
                resize: "Redimensionner",
                createInterface: "Créer une interface",
                removeInterface: "Supprimer l'interface",
                guacamole: "Guacamole",
                vncConsole: "VNC",
                attachVolume: "Attacher un volume",
                detachVolume: "Supprimer le volume",
                createInstanceTemplate: "Créer un modèle"
            },
            control: {
                consoleVNC: {
                    title: "Console VNC",
                    errorMessage: "Impossible d'obtenir la console",
                    successMessage: 'Console received'
                },
                consoleGuac: {
                    title: 'Guac Console',
                    errorMessage: "Impossible d'obtenir la console de guacamole",
                    successMessage: 'Console guacamole reçue',
                    guacMessage: 'Guacamole Connexion ',
                    VMAuthentication: 'VM Authentification',
                    UserAuthentication: "Authentification de l'utilisateur",
                },
                removeInterface: {
                    title: "Supprimer l'interface de la VM",
                    data: 'Données',
                    errorMessage: "Impossible de supprimer l'interface de l'instance",
                    successMessage: 'Interface supprimé',
                    port: 'Port'
                },
                createInterface: {
                    title: "Créer une interface VM",
                    errorMessage: 'Impossible de créer une interface',
                    successMessage: 'Interface créée',
                    data: 'Données',
                    network: 'Réseau'
                },
                resize: {
                    title: "Redimensionner l'instance",
                    data: 'Données',
                    flavor: "Flavor",
                    errorMessage: "Impossible de redimensionner l'instance",
                    successMessage: 'Instance redimensionnée',
                    port: 'Port'
                },
                reboot: {
                    title: "Redémarrage de l'instance",
                    button: 'Redémarrage',
                    data: 'Données',
                    flavor: 'Flavor',
                    errorMessage: "Impossible de redémarrer l'instance",
                    successMessage: 'Instance redemarrée',
                    port: 'Port'
                },
                startAll: {
                    title: 'Démarrer toutes les instances',
                    button: 'Démarrer toutes les Vms',
                    data: 'Données',
                    flavor: 'Flavor',
                    errorMessage: 'Impossible de démarrer une ou plusieur instance(s)',
                    successMessage: 'Toute les instances sont démarrées',
                    port: 'Port',
                    confirmMessage: 'Êtes-vous sûr de vouloir démarrer tous les serveurs ?'
                },
                stopAll: {
                    title: 'Arrêter toutes les instances',
                    button: 'Arrêter toutes les Vms',
                    data: 'Données',
                    flavor: 'Flavor',
                    errorMessage: "Impossible d'arréter une ou plusieur instance(s)",
                    successMessage: 'Toute les instances sont éteintes',
                    port: 'Port',
                    confirmMessage: 'Êtes-vous sûr de vouloir arrêter toutes les machines virtuelles ?'
                },
                guac: {
                    title: 'Connection à la console guacamole',
                    data: 'Données'
                },
                volume: {
                    titleAttach: 'Attacher un volume',
                    titleDetach: 'Detacher un volume',
                    data: 'Données',
                    list: 'volumes'
                }
            }
        },
        trainings: {
            title: 'Formations',
            name: 'Nom',
            description: 'Description',
            number: 'Numéro',
            trainingTemplate: 'Template de formation',
            room: 'Salle de classe',
            locator: 'Locator',
            trainer: 'Formateur',
            podMax: 'Pod max',
            trainingTitle: 'Titre',
            edit: {
                title: 'Modifier la formation'
            },
            buttons: {
                deleteConfirmButton: "Vous ne pourrez pas récupérer cet enregistrement. Êtes-vous sûr ?",
                deleteButtonTitle: "Supprimer la formation"
            },
            associate: {
                successMessage: 'Formateur associé à la formation',
                errorMessage: "Impossible d'associer la formation au formateur",
                title: "Associer le formateur à la formation",
                message: 'Êtes-vous sûr de vouloir associer cet utilisateur ?',
                button: "Associer",
                data: 'Données',
                user: 'Utilisateur'
            },
            disassociate: {
                successMessage: 'Formateur dissocié de la formation',
                errorMessage: 'Impossible de dissocier la formation du formateur',
                title: "Dissocier le formateur de la formation",
                message: "Êtes-vous sûr de vouloir dissocier cet utilisateur ?",
                button: "Dissocier",
                data: 'Données',
            },
            delete: {
                successMessage: 'Formation supprimée',
                errorMessage: 'Erreur de suppression de la formation',
                title: "Supprimer",
                confirmMessage: 'Êtes-vous sûr de vouloir supprimer cette formation?',
                button: "Supprimer"
            },
            ports: {
                successMessage: 'Le port a été mis à jour avec succès',
            },
        },
        volumes: {
            title: "Volumes",
            mountedOn: 'Mounté sur',
            networks: 'Réseaux',
            sizeGo: 'Taille (Go)',
            status: 'Statut',
            name: 'Nom',
            description: 'Description',
            volumeName: 'Nom de volume',
            createdAt: 'Création',
            updatedAt: 'Mise à jour',
            bootable: 'Bootable',
            overview: 'Volume',
            list: {
                status: 'Statut',
                name: 'Nom',
                description: 'Description',
                number: 'Numéro',
                size: 'Taille',
                device: 'Device',
                bootable: 'Bootable',
                volumeSnapshots: 'volumeSnapshots'
            },
            edit: {
                title: "Modifier le volume"
            },
            buttons: {
                deleteConfirmButton: 'Vous ne pourrez pas récupérer cet enregistrement. Êtes-vous sûr ?',
                deleteButtonTitle: "Supprimer le volume"
            },
            delete: {
                successMessage: 'Volume supprimé',
                errorMessage: 'Erreur lors de la suppression du volume',
                title: "Supprimer",
                confirmMessage: 'Êtes-vous sûr de vouloir supprimer ce volume ?',
                button: "Supprimer"
            },
            create: {
                button: 'create',
                title: 'Créer un volume',
                size: 'Taille (GB)'
            },
        },
        files: {
            size: 'Taille',
            name: 'Nom',
            description: 'Description',
            type: 'type',
            number: 'Numéro',
            title: 'Fichier',
            select: 'Sélectionner un fichier',
            create: 'Créer un nouveau répertoire',
            upload: 'Charger un fichier',
            createFolder: 'Créer un répertoire',
            createButton: 'Créer',
            cancelButton: 'Annuler',
            deleteConfirmMessage: 'Êtes-vous sûr de vouloir supprimer ce fichier ?',
            cancel: 'Annuler',
            confirm: 'Confirmer',
            delete: {
                confirmMessage: 'Êtes-vous sûr de vouloir supprimer ce fichier ?',
                title: 'Supprimer',
                button: 'Supprimer'
            }
        },
        hypervisors: {
            name: 'Nom',
            numberOfVMs: 'Nombre de VMs',
            totalCPU: 'Total CPU',
            vcpuAllocationRatio: 'vCPU Allocation Ratio',
            usedvCPU: 'vCPU utilisé',
            freevCPU: 'vCPU libre ',
            usedDisk: 'Disque utilisé (GB)',
            freeDisk: 'Disque libre (GB)',
            totalDisk: 'Total disque (GB)',
            summary: 'Ressource',
            globalUsage: 'Utilisation globale',
            state: 'Etat',
            freeRAM: 'RAM libre (GB)',
            usedRAM: 'RAM utilisé (GB)'
        },
        tasks: {
            name: 'Nom',
            description: 'Description',
            number: 'Numéro',
            overallProgress: 'Progression',
            status: 'Statut',
            type: 'Type',
            createdAt: 'Créé par'
        },
        dashboard: {
            welcomeTitle: 'Bienvenue sur le site de Remote Labs',
            title: 'Tableau de bord'
        },
        help: {
            diskBusHelp: 'Disk bus Type help',
        },
        emptyMessage: 'Aucune donnée trouvée',
        LoadingMessage: 'Chargement...',
        ErrorMessage: 'Erreur!',
        notAllowed: 'Non autorisé',
        notAllowedMessage: "Vous ne disposez pas des autorisations nécessaires pour accéder à cette page. Veuillez contacter votre administrateur si vous pensez qu'il s'agit d'une erreur."
    },
};

export default frenchCustomMessages;