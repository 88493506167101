
import decodeJwt from 'jwt-decode';
import gravatar from 'gravatar';
import axios from 'axios';

const AuthProvider = {
  // authentication
  login: async ({ username, password }) => {
    try {
      const data = { username, password };
      const options = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      const res = await axios.post('/api/v1/auth/login', data, options);
      if (res.data && res.data.token) {
        localStorage.setItem('token', res.data.token);
      }
      else throw new Error('No token found');
    }
    catch (error) {
      if (error.response && error.response.data && error.response.data.message) throw new Error(error.response.data.message);
      else throw new Error(error);
    }
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: async () => {
    if (localStorage.getItem('token')) return Promise.resolve();
    else return Promise.reject();
  },
  logout: async () => {
    localStorage.removeItem('token');
    localStorage.removeItem('guacamoleToken');
  },
  getIdentity: () => {
    try {
      const { sub } = decodeJwt(localStorage.getItem('token'));
      return Promise.resolve({
        id: sub.id,
        fullName: sub.fullName,
        avatar: gravatar.url(sub.email, { protocol: 'https', s: '40', r: 'g', d: 'identicon' }, false)
      });
    }
    catch (error) {
      return Promise.reject(error);
    }
  },
  // authorization
  getPermissions: (params) => {
    try {
      const { sub } = decodeJwt(localStorage.getItem('token'));
      return sub.roles ? Promise.resolve(sub.roles) : Promise.reject();
    }
    catch (error) {
      return Promise.reject(error);
    }
  },
};

export default AuthProvider;