import { Typography, Box, SvgIcon } from '@material-ui/core';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => {
    return ({
        sectionBox: {
            marginBottom: 5,
            borderBottom: '2px solid',
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.main
        },
        sectionText: {

        },
        sectionIcon: {
            verticalAlign: 'text-bottom',
            paddingRight: 5,
        },
    })
});

const SectionField = (props) => {
    const classes = useStyles(props);
    return (
        <Box className={classes.sectionBox}>
            <Typography variant='h6' gutterBottom className={classes.sectionText}>
                <SvgIcon component={props.icon} className={classes.sectionIcon} /> {props.label}
            </Typography>
        </Box>
    )
};

export default SectionField;