import { useEffect, useState } from 'react';
import {
  Show, TextField, SimpleShowLayout, TabbedShowLayout, Tab, useDataProvider,
  Loading, Error, FunctionField, useTranslate, useGetOne
}from 'react-admin';


import { useParams } from 'react-router-dom';
import { Divider } from "@mui/material";
import { CustomList } from '../../custom';
import BasicBreadcrumbs from '../../components/breadcrumb';
import ComponentTitle from '../../components/componentTitle';
import DeployTrainingTemplate from './deployTrainingTemplate';
import makeStyles from "@material-ui/core/styles/makeStyles";
import { blue } from '@mui/material/colors';


const useStyles = makeStyles((theme) => ({
  alignText: {
    marginLeft: "auto",
    marginRight: "auto",
    color : blue
  }
}));


const WorkspaceShow = (props) => {

  let { id } = useParams();
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const [commonPod, setCommonPod] = useState([]);
  const [userPod, setUserPod] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  if (!id) id = props.id;   //id for myworkspace
  const { data, isLoading } = useGetOne('workspaces', { id });

  //const { isLoading, record } = useShowController();
  const classes = useStyles();
  // retrieve common and user pod of the workspace
  useEffect(() => {
    dataProvider.getOneByFilter('pods', {
      pagination: { page: 1, perPage: 5 },
      sort: { field: 'room', order: 'ASC' },
      filter: { room: id },
    })
      .then(({ data }) => {
        setLoading(false);
        for (const pod in data) {
          data[pod].type === "common" ?
            setCommonPod([data[pod].name, data[pod].id, data[pod].type]) :
            setUserPod([data[pod].name, data[pod].id, data[pod].type])
        }
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      })
  }, []);

  if (loading) return <Loading />;
  if (isLoading) return <div>{translate('app.LoadingMessage')}</div>;
  if (error) return <Error error={error.message ? error.message : error} />;
  if (!commonPod || !userPod) return null;
  // TODO : add custom page for no pod found
  
  return (
    <>
      <BasicBreadcrumbs name={data.name} />
      <Show {...props}>
        {props.myWorkspace ? <ComponentTitle label={<> {translate('app.workspaces.title')} <TextField source='number' /> </>} /> : ""}
        <TabbedShowLayout spacing={4} divider={<Divider flexItem />} >
          <Tab label={translate('app.general.title')}>
            <SimpleShowLayout sx={{ margin: 2 }}>
              <TextField source='number' />
            </SimpleShowLayout>
            <DeployTrainingTemplate workspaceId={id}/>
          </Tab>

          <Tab label={translate('app.pods.common')} path='commonPod'>
            <SimpleShowLayout sx={{ margin: 2 }}>
              <FunctionField label={translate('app.pods.name')} render={record => commonPod[0]} />
              <CustomList source="networks" id={commonPod[1]} label={translate('app.pods.networks')} />
              <CustomList source="routers" id={commonPod[1]} label={translate('app.pods.routers')} />
              <CustomList source="vms" id={commonPod[1]} label={translate('app.pods.vms')} podName={commonPod[0]} type={commonPod[2]} />
            </SimpleShowLayout>
          </Tab>

          <Tab label={translate('app.pods.user')} path='userPod'>
            <SimpleShowLayout sx={{ margin: 2 }}>
              <FunctionField label={translate('app.pods.name')} render={record => userPod[0]} />
              <CustomList source="networks" id={userPod[1]} label={translate('app.pods.networks')}/>
              <CustomList source="routers" id={userPod[1]} label={translate('app.pods.routers')}/>
              <CustomList source="vms" id={userPod[1]} label={translate('app.pods.vms')} podName={userPod[0]} type={userPod[2]} />
            </SimpleShowLayout>
          </Tab>

        </TabbedShowLayout>
      </Show>
    </>
  );
};

export default WorkspaceShow;