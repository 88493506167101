import React, { Fragment } from 'react';
import { ExpandMore, ChevronRight } from '@material-ui/icons';
import { List, MenuItem, ListItemIcon, Typography, Collapse, Tooltip } from '@material-ui/core';
import classnames from 'classnames';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  theme => ({
    icon: { minWidth: theme.spacing(5) },
    sidebarIsOpen: {
      paddingLeft: 25,
      transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
    sidebarIsClosed: {
      paddingLeft: 0,
      transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
    menuItem: {},
    menuItemName: {
      color: theme.palette.text.secondary,
      flexGrow: 1,
    },
    openMenuItem: {}
  }),
  { name: 'CustomMenuItem' }
);

const CustomMenuItem = ({
  handleToggle,
  sidebarIsOpen,
  isOpen,
  name,
  icon,
  children,
  dense
}) => {
  const classes = useStyles();

  const header = (
    <MenuItem
      key={name}
      dense={dense}
      button
      onClick={handleToggle}
      className={classnames(
        classes.menuItem,
        { [classes.openMenuItem]: isOpen }
      )}
    >
      <ListItemIcon className={classes.icon}>
        {isOpen ? <ExpandMore /> : icon}
      </ListItemIcon>
      <Typography
        variant="inherit"
        className={classnames(
          classes.menuItemName,
          'menuItemName'
        )}
      >
        {name}
      </Typography>
      <ListItemIcon className={classes.icon}>
        {isOpen ? null : <ChevronRight style={{ paddingLeft: 10,  }}/>}
      </ListItemIcon>
    </MenuItem>
  );

  return (
    <Fragment>
      {sidebarIsOpen || isOpen ? (
        header
      ) : (
        <Tooltip title={name} placement="right">
          {header}
        </Tooltip>
      )}
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List
          dense={dense}
          component="div"
          disablePadding
          className={
            sidebarIsOpen
              ? classes.sidebarIsOpen
              : classes.sidebarIsClosed
          }
          style={{ display: "flex", flexDirection: "column", alignItems: 'left' }}
        >
          {children}
        </List>
      </Collapse>
    </Fragment>
  );
};

export default CustomMenuItem;