import React from 'react';
import { FunctionField } from 'react-admin';

const StatusField = (props) => (
    <FunctionField
        {...props}
        label="Status"
        render={(record) => {
            if (!record) return '';

            // Display task state if it is not null, otherwise show osServerStatus
            const status = record.task_state ? record.task_state : record.osServerStatus;

            // Determine color based on task state or osServerStatus
            let displayStatus = status ? status.toUpperCase() : '';
            let color = 'inherit';

            if (record.task_state) {
                color = 'orange'; // Task is in progress
            } else {
                // Adjust color for osServerStatus if no task is in progress
                switch (record.osServerStatus) {
                    case 'ACTIVE':
                        displayStatus = 'RUNNING';
                        color = 'green';
                        break;
                    case 'SHUTOFF':
                        displayStatus = 'STOPPED';
                        color = 'red';
                        break;
                    default:
                        color = 'inherit';
                        break;
                }
            }

            return <span style={{ color }}>{displayStatus}</span>;
        }}
    />
);

export default StatusField;
