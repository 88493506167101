import axios from 'axios';
import { useState } from 'react';
import { useTranslate, useSafeSetState, useRedirect } from 'ra-core';
import { Field, Form } from 'react-final-form';
import {
  Button, CardActions, CircularProgress, TextField, Typography,
  Card, CardContent
} from '@material-ui/core';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { CustomTheme } from '../custom';
import { useParams } from 'react-router-dom';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundImage: 'url(/background-login.jpg)',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    height: '1px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  card: {
    minWidth: 300,
    maxWidth: 400,
    marginTop: '6em',
  },
  form: {
    padding: '0 1em 1em 1em',
    margin: theme.spacing(1),
    // add responsive behavior
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.5),
    },
  },
  input: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
    width: '100%', // make input full width

    //Override the input base styles
    '& .MuiInputBase-input': {
      height: '2em', // Adjust the height as needed
      padding: theme.spacing(1), // Add padding to ensure text is centered
    },
  },
  button: {

  },
  title: {
    marginTop: 10
  },
  successMessage: {
    marginTop: 10
  },
  errorMessage: {
    marginTop: 10,
    color: 'red'
  },
  errorMessageDetails: {
    marginTop: 10,
    color: 'black'
  }
}),
  { name: 'RaResetPassword' }
);

const Input = ({
  className,
  meta: { touched, error }, // eslint-disable-line react/prop-types
  input: inputProps, // eslint-disable-line react/prop-types
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    className={className} // Apply custom styles here
    {...inputProps}
    {...props}
    fullWidth
  />
);


const ResetPasswordByLink = (props) => {
  const classes = useStyles(props);
  const [loading, setLoading] = useSafeSetState(false);
  const translate = useTranslate();
  const redirect = useRedirect();
  const [requestSent, setRequestSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  const handleClickShowConfirmPassword = () => {
    setConfirmShowPassword(!showConfirmPassword);
  };

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const { userId, token } = useParams();

  const redirectToLogin = () => {
    redirect('/login');
  }


  const validateForm = values => {
    const errors = {};
    // Custom validation logic here, e.g., password matching
    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = translate('app.resetPassword.validation');
    }
    // You can add more validation rules here

    // Password must contain at least 1 letter and 1 number
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d).+$/;

    if (!passwordRegex.test(values.password)) {
      errors.password = translate('app.resetPassword.passwordValidation');
    }

    return errors;
  };

  const submit = async (values) => {
    try {

      setLoading(true);
      const data = { password: values.password };
      const options = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      await axios.post(`/api/v1/auth/reset-password/${userId}/${token}`, data, options);
      setLoading(false);
      setRequestSent(true);
    }
    catch (error) {
      setLoading(false);
      if (error.response && error.response.data && error.response.data.message) {
        if (error.response.data.detail) {
          const detailedMessage = `${error.response.data.message} : ${error.response.data.detail.errorMessage}`;
          setErrorMessage(detailedMessage);
        } else {
          const message = error.response.data.message;
          setErrorMessage(message);
        }
      } else {
        const defaultError = typeof error === 'string' ? error : typeof error === 'undefined' || !error.message ? 'ra.auth.sign_in_error' : error.message;
        setErrorMessage(defaultError);
      }
    }
  };

  const required = (value) => (value ? undefined : translate('ra.validation.required'));

  const renderForm = (props) => {
    return (
      <Card className={classes.card}>
        <Form
          validate={validateForm}
          onSubmit={submit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Typography align='center' color='primary' variant='h6' className={classes.title}>{translate('app.resetPassword.title')}</Typography>
              <div className={classes.form}>
                <div className={classes.input}>
                  <Field
                    autoFocus
                    id="password"
                    name="password"
                    component={Input}
                    label={translate('app.resetPassword.newPassword')}
                    type={showPassword ? 'text' : 'password'} // Toggle between 'text' and 'password'
                    disabled={loading}
                    validate={required}
                    InputProps={{
                      className: classes.input,
                      style: { height: '2em', padding: '10px', marginTop: '20px' },
                      // Add InputAdornment for visibility toggle
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Field
                    id="confirmPassword"
                    name="confirmPassword"
                    component={Input}
                    label={translate('app.resetPassword.confirmPassword')}
                    type={showConfirmPassword ? 'text' : 'password'} // Toggle between 'text' and 'password'
                    disabled={loading}
                    validate={required}
                    InputProps={{
                      className: classes.input,
                      style: { height: '2em', padding: '10px', marginTop: '20px' },
                      // Add InputAdornment for visibility toggle
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownConfirmPassword}
                            edge="end"
                          >
                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
              <CardActions>
                <Button fullWidth={true} variant="contained" type="submit" color="primary" disabled={loading} className={classes.button}>
                  {loading && (
                    <CircularProgress
                      className={classes.icon}
                      size={18}
                      thickness={2}
                    />
                  )}
                  {translate('app.resetPassword.resetButton')}
                </Button>
              </CardActions>
            </form>
          )}
        />
      </Card>
    );
  }

  const renderSuccess = () => {
    return (
      <Card className={classes.card}>
        <CardContent>
          <Typography align='center' color='primary' variant='h5' className={classes.successMessage}>{translate('app.resetPassword.success')}</Typography>
        </CardContent>
        <CardActions>
          <Button fullWidth={true} onClick={redirectToLogin} variant="contained" color="primary" className={classes.button}>
            {translate('app.resetPassword.redirectToLoginButton')}
          </Button>
        </CardActions>
      </Card>
    );
  }

  const renderError = () => {
    return (
      <Card className={classes.card}>
        <CardContent>
          <Typography align='center' variant='h5' className={classes.errorMessage}>{translate('app.resetPassword.error1')}</Typography>
          <Typography align='center' variant='h5' className={classes.errorMessage}>{translate('app.resetPassword.error2')}</Typography>
          <Typography align='center' variant='h6' className={classes.errorMessageDetails}>{errorMessage}</Typography>
        </CardContent>
        <CardActions>
          <Button fullWidth={true} onClick={redirectToLogin} variant="contained" color="primary" className={classes.button}>
            {translate('app.resetPassword.redirectToLoginButton')}
          </Button>
        </CardActions>
      </Card>
    );
  }

  let content = <div></div>;
  if (errorMessage) content = renderError();
  else if (requestSent) content = renderSuccess();
  else content = renderForm();

  return (
    <ThemeProvider theme={CustomTheme}>
      <div className={classes.main} >
        {content}
      </div>
    </ThemeProvider>
  );
};

export default ResetPasswordByLink;