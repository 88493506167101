import { Typography, Box, SvgIcon } from '@material-ui/core';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => {
    return ({
        viewTitleBox: {
            marginBottom: 5,
            borderBottom: '2px solid',
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
            marginTop: 10,
            marginLeft: 10,
            display: 'flex',
            alignItems: 'center',
        },
        viewTitleText: {

        },
        viewTitleIcon: {
            fontSize: 40,
            verticalAlign: 'text-bottom',
            paddingRight: 5,
            paddingTop: 5,
        },
    })
});

const ComponentTitle = (props) => {
    const classes = useStyles(props);
    if (props.icon) return (
        <Box className={classes.viewTitleBox}>
            <Typography variant='h4' gutterBottom className={classes.sectionText}>
                <SvgIcon component={props.icon} className={classes.viewTitleIcon} />
                {props.label}
            </Typography>
        </Box>
    )
    else return (
        <Box className={classes.viewTitleBox}>
            <Typography variant='h4' gutterBottom className={classes.viewTitleText}>{props.label}</Typography>
        </Box>
    )
};


export default ComponentTitle;
