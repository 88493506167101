import axios from 'axios';
import {
IconButton,
Tooltip
} from '@mui/material';
import {
 useNotify,
 useTranslate
} from 'react-admin';
import handleError from './handleError';
import { Cancel } from '@mui/icons-material';
import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import PlugIcon from '@mui/icons-material/Power'; // Replace with a suitable plug icon
import UnplugIcon from '@mui/icons-material/PowerOff'; // Replace with a suitable unplug icon

const useStyles = makeStyles((theme) => ({
  listCell: {
    textAlign: 'left',
    width: '100%',
    maxWidth: 360,
    padding: '0 0'
  },
  item: {
    padding: 0,
  },
}));

const ManageRouterInterface = ({portId, state}) => {
    const translate = useTranslate();
    const notify = useNotify();
  
    const handleClick = async (state) => {
      try {
        const options = {
          headers: {},
        };
  
        const sendData = {
          state
        };
  
        const token = localStorage.getItem('token');
        options.headers.Authorization = `Bearer ${token}`;
  
        const res = await axios.put(`/api/v1/networks/ports/${portId}`, sendData, options);
        if (res.status !== 200) {
          notify(translate('app.networks.ports.errorMessage'), { type: 'error' });
        }
        else {
          notify(translate('app.trainings.ports.successMessage'), { type: 'success' });
        };
      } catch (error) {
        handleError(error, notify);
      };
    };
  
     // Use icons instead of buttons with text
     return (
        <Tooltip title={state === "ACTIVE" ? translate('app.networks.ports.disconnect') : translate('app.networks.ports.connect')}>
        <IconButton
            onClick={() => handleClick(state === "ACTIVE" ? false : true)}  // Toggle the state
            color={state === "ACTIVE" ? "primary" : "secondary"}  // Color based on state
        >
            {state === "ACTIVE" ? <UnplugIcon /> : <PlugIcon />}
        </IconButton>
        </Tooltip>
    );
  };
  
  export default ManageRouterInterface;