import { Fragment, useState } from 'react';
import {
    SimpleForm, useTranslate, Toolbar, SaveButton,
    Button, ReferenceArrayInput,
    useNotify, useRefresh, AutocompleteArrayInput,
} from 'react-admin';
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import { Link } from '@material-ui/icons';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { ViewTitle } from '../../components';
import { Cancel } from '@mui/icons-material';
import handleError from '../../components/handleError';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        display: 'flex',
    },
    spacer: {
        flex: 1,
    },
    buttonMarginLeft: {
        marginLeft: 10
    },
    flexBox: {
        width: 'auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    inputMarginLeft: {
        marginLeft: 20
    },
    border: {
        border: '1px solid #6b489d'
    }
}));

const Validate = (values) => {
    const errors = {};
    if (!values.users) errors.users = 'ra.validation.required';
    return errors;
};

const AssociateUserToPod = ({ permissions }) => {

    const translate = useTranslate();
    const [showDialog, setShowDialog] = useState(false);
    const notify = useNotify();
    const refresh = useRefresh();

    const AssociateUser = async (data) => {
        const users = data.users;
        users.forEach(async (user) => {
            try {
                const options = {
                    headers: {},
                };

                const sendData = {
                    pod: data.id
                };

                const token = localStorage.getItem('token');
                options.headers.Authorization = `Bearer ${token}`;

                const res = await axios.post(`/api/v1/users/${user}/associate`, sendData, options);
                if (res.status !== 200) {
                    notify(translate('app.pods.associate.errorMessage'), { type: 'error' });
                }
                else {
                    notify(translate('app.pods.associate.successMessage'), { type: 'success' });
                    handleCloseClick();
                    refresh();
                };
            }
            catch (error) {
                handleError(error, notify);
            }
        });
    }


    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const fullNameField = choice => `${choice.firstname} ${choice.lastname}`;
    const CreateToolbar = (props) => {
        const classes = useStyles();
        const translate = useTranslate();
        return (
            <Toolbar className={classes.toolbar}>
                <Button label="ra.action.cancel" size="medium" onClick={handleCloseClick} className={classes.border} >
                    <Cancel />
                </Button>
                <SaveButton label={translate('app.buttons.send')} className={classes.buttonMarginLeft} />
            </Toolbar>
        );
    };

    const filterToQuery = searchText => ({ email: `${searchText}` });

    return (
        <Fragment>
            <Button label={translate('app.pods.associate.button')} onClick={handleClick}><Link /></Button>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label={translate('app.pods.associate.title')}
            >
                <DialogTitle><ViewTitle label={translate('app.pods.associate.title')} icon={Link} /></DialogTitle>
                <DialogContent>
                    <SimpleForm validate={Validate} toolbar={<CreateToolbar />}
                        onSubmit={AssociateUser} >
                        <ReferenceArrayInput
                            source="users"
                            reference="users"
                            filter={{ "roles": "trainee" }}
                            label={translate('app.pods.associate.user')}
                        >
                            <AutocompleteArrayInput optionText={fullNameField} filterToQuery={filterToQuery} />
                        </ReferenceArrayInput>


                    </SimpleForm>
                </DialogContent>
            </Dialog>
        </Fragment>
    );
};

export default AssociateUserToPod;

