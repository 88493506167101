import { Loading } from 'react-admin';
import Loadable from 'react-loadable';

const ClassroomsList = Loadable({ loader: () => import('./classroomsList'), loading: Loading });
const ClassroomShow = Loadable({ loader: () => import('./classroomShow'), loading: Loading });
const ClassroomCreate = Loadable({ loader: () => import('./classroomCreate'), loading: Loading });
const ClassroomEdit = Loadable({ loader: () => import('./classroomEdit'), loading: Loading });

export default { 
  list: ClassroomsList,
  show: ClassroomShow,
  create: ClassroomCreate,
  edit: ClassroomEdit
};