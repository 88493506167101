import { Fragment, useState } from 'react';
import {
    SimpleForm, useTranslate, FormDataConsumer, Toolbar, SaveButton,
    Button, useNotify, useRefresh, AutocompleteArrayInput,
    useGetManyReference,

} from 'react-admin';
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import { LinkOff } from '@material-ui/icons';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { ViewTitle } from '../../components';
import { Cancel } from '@material-ui/icons';
import handleError from '../../components/handleError';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        display: 'flex',
    },
    spacer: {
        flex: 1,
    },
    buttonMarginLeft: {
        marginLeft: 10
    },
    flexBox: {
        width: 'auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    inputMarginLeft: {
        marginLeft: 20
    },
    border: {
        border: '1px solid #6b489d'
    }
}));

const Validate = (values) => {
    const errors = {};
    if (!values.users) errors.users = 'ra.validation.required';
    return errors;
};

const DisassociateTrainerToTraining = ({ permissions, ...props }) => {
    const translate = useTranslate();
    const [showDialog, setShowDialog] = useState(false);
    const notify = useNotify();
    const refresh = useRefresh();

    const GetTrainers = (trainers) => {
        const { data } = useGetManyReference("users", {
            target: "_id",
            id: trainers,
            sort: { field: 'id', order: 'ASC' }
        });
        return (
            <AutocompleteArrayInput source="users" choices={data} optionText={fullNameField} />
        )
    }


    const DisassociateTrainer = async (data) => {
        const users = data.users;
        users.forEach(async (user) => {
            try {
                const options = {
                    headers: {},
                };

                const sendData = {
                    userId: user
                };

                const token = localStorage.getItem('token');
                options.headers.Authorization = `Bearer ${token}`;

                const res = await axios.post(`/api/v1/trainings/${data.id}/disassociate`, sendData, options);
                if (res.status !== 200) {
                    notify(translate('app.trainings.disassociate.errorMessage'), { type: 'error' });
                }
                else {
                    notify(translate('app.trainings.disassociate.successMessage'), { type: 'success' });
                    handleCloseClick();
                    refresh();
                };
            }
            catch (error) {
             handleError(error, notify);
            }
        });
    }


    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const fullNameField = choice => `${choice.firstname} ${choice.lastname}`;
    const CreateToolbar = (props) => {
        const classes = useStyles();
        const translate = useTranslate();
        return (
            <Toolbar className={classes.toolbar}>
                <Button label="ra.action.cancel" size="medium" onClick={handleCloseClick} c >
                    <Cancel />
                </Button>
                <SaveButton label={translate('app.buttons.send')} className={classes.buttonMarginLeft} />
            </Toolbar>
        );
    };

    return (
        <Fragment>
            <Button label={translate('app.trainings.disassociate.button')} onClick={handleClick}><LinkOff /></Button>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label={translate('app.trainings.disassociate.title')}
            >
                <DialogTitle><ViewTitle label={translate('app.trainings.disassociate.title')} icon={LinkOff} /></DialogTitle>
                <DialogContent>
                    <SimpleForm validate={Validate} toolbar={<CreateToolbar />}
                        onSubmit={DisassociateTrainer} >
                        <FormDataConsumer>
                            {({ formData, ...rest }) => formData.trainer &&
                                GetTrainers(formData.trainer)
                            }
                        </FormDataConsumer>
                    </SimpleForm>
                </DialogContent>
            </Dialog>
        </Fragment>
    );
};

export default DisassociateTrainerToTraining;

