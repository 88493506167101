import { Loading } from 'react-admin';
import Loadable from 'react-loadable';

const TrainingList = Loadable({ loader: () => import('./trainingList'), loading: Loading });
const TrainingEdit = Loadable({ loader: () => import('./trainingEdit'), loading: Loading });
const TrainingShow = Loadable({ loader: () => import('./trainingShow'), loading: Loading });

export default { 
  list: TrainingList,
  edit: TrainingEdit,
  show: TrainingShow,
};