import { Chip } from "@mui/material";
import { Stack } from '@mui/material';
import { useRecordContext } from 'react-admin';

const VisibilityField = () => {
  const record = useRecordContext();
  
  if (record) {
    const visibilities = record.visibility;
    return (
      <Stack direction="row" spacing={1}>
        {visibilities.map(visibility => (
          <Chip color="primary" key={visibility} label={visibility} />
        ))}
      </Stack>

    );
  }
  else return null;
};


export default VisibilityField;