import { Fragment, useState } from 'react';
import {
    useTranslate, Button, useRecordContext,
    useNotify, useRefresh,Confirm,
} from 'react-admin';
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import { LinkOff } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        display: 'flex',
    },
    spacer: {
        flex: 1,
    },
    buttonMarginLeft: {
        marginLeft: 10
    },
    flexBox: {
        width: 'auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    inputMarginLeft: {
        marginLeft: 20
    },
    border: {
        border: '1px solid #6b489d'
    }
}));


const DisAssociateUserToPod = ({ permissions, ...props }) => {
    const classes = useStyles();
    const translate = useTranslate();
    const [open, setOpen] = useState(false);
    const record = useRecordContext();
    const notify = useNotify();
    const refresh = useRefresh();

    const handleConfirm = async (data) => {
        setOpen(false);
            try {
                const options = {
                    headers: {},
                };

                const userId = record.id;

                const sendData = {
                    pod: record.pod
                };

                const token = localStorage.getItem('token');
                options.headers.Authorization = `Bearer ${token}`;

                const res = await axios.post(`/api/v1/users/${userId}/disassociate`, sendData, options);
                if (res.status !== 200) {
                    notify(translate('app.pods.disassociate.errorMessage'), { type: 'error' });
                }
                else {
                    notify(translate('app.pods.disassociate.successMessage'), { type: 'success' });
                    refresh();
                };
            }
            catch (error) {
                if (error.response && error.response.data && error.response.data.message) {
                    {
                        error.response.data.detail ? notify(`${error.response.data.message} : ${error.response.data.detail.errorMessage}`, { type: 'error' }) :
                            notify(`${error.response.data.message}`, { type: 'error' })
                    };
                }
                else throw new Error(error);
            }
    }

    const handleDialogClose = () => setOpen(false);
    const handleClick = () => {
        setOpen(true);
    };

    return (
        <Fragment>
            <Button label={translate('app.pods.disassociate.button')} onClick={handleClick}><LinkOff /></Button>
            <Confirm
                isOpen={open}
                title={translate('app.pods.disassociate.title')}
                content={translate('app.pods.disassociate.message')}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </Fragment>
    );
};

export default DisAssociateUserToPod;

