import { Fragment, } from 'react';
import {
    Edit, SimpleForm, TextInput, PasswordInput,
    useTranslate, AutocompleteArrayInput, SelectInput,
    Toolbar, SaveButton, email, DateField,
    ReferenceField, TextField, DeleteWithConfirmButton, useLocaleState,
    useNotify,
    useGetIdentity

} from 'react-admin';

import { BackButton, SectionField, ViewTitle } from '../../components';

import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@mui/styles';
import { Person, VpnKey, PermIdentity, VerifiedUser, Info } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        display: 'flex',
    },
    spacer: {
        flex: 1,
    },
    buttonMarginLeft: {
        marginLeft: 10
    },
    flexBox: {
        width: 'auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    inputMarginLeft: {
        marginLeft: 20
    },
    infoContainer: {
        width: 500,
        padding: '6px',
    },
    infoField: {
        fontSize: '0.75rem',
    },
    infoTitle: {
        fontSize: '0.75rem',
        fontWeight: 'bold',
        textAlign: 'right',
        maxWidth: '12%'
    },
    createdByCell: {
        width: '300',
        whiteSpace: 'nowrap'
    },
}));

const MyProfile = () => {
    const translate = useTranslate();

    const { identity, isLoading: identityLoading } = useGetIdentity();
    if (identityLoading || !identity) return <>{translate('app.loadingMessage')}</>;
    const userId = identity.id;

    return (
        <>
            <UserEdit id={userId} />
        </>

    );
}


const Validate = (values) => {
    const errors = {};
    if (!values.email) errors.email = 'ra.validation.required';
    if (!values.firstname) errors.firstname = 'ra.validation.required';
    if (!values.lastname) errors.lastname = 'ra.validation.required';
    //if (!values.roles) errors.roles = 'ra.validation.required';
    return errors;
};

const EditToolbar = () => {
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <Toolbar className={classes.toolbar}>
            <BackButton />
            <SaveButton label={translate('app.buttons.update')} className={classes.buttonMarginLeft} />
            <span className={classes.spacer} />
            <DeleteWithConfirmButton size='medium' />
        </Toolbar>
    );
};

const UserEdit = ({ permissions, ...props }) => {
    const classes = useStyles();
    const translate = useTranslate();
    const [locale] = useLocaleState();
    const notify = useNotify();

    const onSuccess = () => {
        notify(translate('app.users.edit.successMessage'), { type: 'success' });
        //redirect('list', 'users');
    };

    const onError = (error) => {
        if (error.body) {
            {
                error.body.detail ? notify(`${error.body.message} : ${error.body.detail.errorMessage}`, { type: 'error' }) :
                    notify(`${error.body.message}`, { type: 'error' })
            };
        }
        else {
            notify(translate('app.users.edit.errorMessage'), { type: 'error' });
        }
    };



    return (
        <Fragment>
            <ViewTitle label={translate('app.users.titles.edit')} icon={Person} />
            <Edit
                id={props.id}
                resource="users"
                redirect={false}
                mutationOptions={{ onSuccess, onError }} mutationMode="pessimistic">
                <SimpleForm variant='outlined' validate={Validate} toolbar={<EditToolbar />}>

                    <SectionField label={translate('app.users.sections.credentials')} icon={VpnKey} />
                    <Box className={classes.flexBox}>
                        <TextInput variant='outlined' source='email' validate={email()} style={{ minWidth: 250 }} />
                        <PasswordInput variant='outlined' source='password' className={classes.inputMarginLeft} />
                    </Box>

                    <SectionField label={translate('app.users.sections.identity')} icon={PermIdentity} />
                    <Box className={classes.flexBox}>
                        <TextInput variant='outlined' source='firstname' />
                        <TextInput variant='outlined' source='lastname' className={classes.inputMarginLeft} />
                        <SelectInput variant='outlined' source='language' choices={[
                            { id: 'en', name: 'app.users.languages.en' },
                            { id: 'fr', name: 'app.users.languages.fr' },
                        ]} className={classes.inputMarginLeft} />
                    </Box>

                    <SectionField label={translate('app.users.sections.rights')} icon={VerifiedUser} />
                    <Box className={classes.flexBox}>
                        <AutocompleteArrayInput variant='outlined' source='roles' choices={[
                            { id: 'admin', name: 'app.users.roles.admin' },
                            { id: 'designer', name: 'app.users.roles.designer' },
                            { id: 'trainer', name: 'app.users.roles.trainer' },
                            { id: 'trainee', name: 'app.users.roles.trainee' },
                        ]} disabled />
                    </Box>

                    <SectionField label={translate('app.misc.infosTitle')} icon={Info} />
                    <Grid container spacing={1} className={classes.infoContainer}>
                        <Grid item xs={2} className={classes.infoTitle} >{translate('app.misc.createdAt')}: </Grid>
                        <Grid item xs={4} className={classes.infoField} ><DateField source='createdAt' showTime locales={locale} /></Grid>
                        <Grid item xs={1} className={classes.infoTitle} >{translate('app.misc.createdBy')}: </Grid>
                        <Grid item xs={5} className={classes.infoField} >
                            <ReferenceField source='createdBy' reference='users'>
                                <TextField source='email' />
                            </ReferenceField>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} className={classes.infoContainer}>
                        <Grid item xs={2} className={classes.infoTitle} >{translate('app.misc.updatedAt')}: </Grid>
                        <Grid item xs={4} className={classes.infoField} ><DateField source='updatedAt' showTime locales={locale} /></Grid>
                        <Grid item xs={1} className={classes.infoTitle} >{translate('app.misc.updatedBy')}: </Grid>
                        <Grid item xs={5} className={classes.infoField} >
                            <ReferenceField source='updatedBy' reference='users'>
                                <TextField source='email' />
                            </ReferenceField></Grid>
                    </Grid>

                </SimpleForm>
            </Edit>
        </Fragment>
    );
};


export default MyProfile;