import {
    ListItem, ListItemText, List, ListItemIcon, IconButton,
    Typography,
    Chip,
    Box
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import {
    useRecordContext,
} from 'react-admin';
import { makeStyles } from '@mui/styles';
import ManageRouterInterface from '../../components/manageRouterInterface';


const useStyles = makeStyles((theme) => ({
    listCell: {
        textAlign: 'left',
        width: '100%',
        maxWidth: 360,
        padding: '0 0'
    },
    item: {
        padding: 0,
    },
}));

const ShowRouterInterfaces = () => {
    const record = useRecordContext();
    const sort = { field: 'id', order: 'ASC' };
    const classes = useStyles();

    if (record && record.netifs) {
        const netifs = record.netifs;

        let filteredNetwork = netifs.filter((netif) => {
            return netif.type === 'internal';
        });

        return (
            <>
            <List classes={{ root: classes.listCell }} dense>
                {filteredNetwork.map((netif) => (
                    <ListItem key={netif.addressIp} classes={{ root: classes.item }} disablePadding>
                        <ListItemIcon sx={{ minWidth: '20px' }}>
                            <CircleIcon fontSize="small" sx={{ fontSize: '0.50rem', color: netif.osPortStatus === "ACTIVE" ? "green" : "red" }} />
                        </ListItemIcon>
                        <ListItemText 
                            primary={
                                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                                    {netif.ip_address}
                                </Typography>
                            } 
                        />
                        <ListItemText 
                            primary={
                                <Chip 
                                label={netif.osPortStatus === "ACTIVE" ? "Connected" : "Disconnected"}
                                color={netif.osPortStatus === "ACTIVE" ? "success" : "error"}
                                    size="small"
                                />
                            } 
                        />
                        <Box>
                            <ManageRouterInterface portId={netif.osPortId} state={netif.osPortStatus} />
                        </Box>
                    </ListItem>
                ))}
            </List>
            </>
        );
    }
    else return null;
};

export default ShowRouterInterfaces;