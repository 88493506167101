import { useEffect } from 'react';
import { Layout, useSetLocale } from 'react-admin';
import decodeJwt from 'jwt-decode';
import CustomAppBar from './customAppBar';
import CustomMenu from './customMenu';

const CustomAppLayout = (props) => {

  const setLocale = useSetLocale();

  useEffect(() => {
    if(localStorage.getItem('token')) {
      const { sub } = decodeJwt(localStorage.getItem('token'));
      /*if(sub.language) {
        console.log(`Setting locale to ${sub.language}`);
        setLocale(sub.language);
      }*/
    }
  }, [setLocale]);

  return <Layout {...props} appBar={CustomAppBar} menu={CustomMenu}  />;
}

export default CustomAppLayout;