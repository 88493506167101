import { Chip } from '@material-ui/core';
import { Stack } from '@mui/system';
import { useRecordContext } from 'react-admin';

const TagsField = () => {
    const record = useRecordContext();

    if (record) {
        const tags = record.tags;
        return (
            <Stack direction="row" spacing={1}>
                {tags.map(tag => (
                    <Chip color="primary" key={tag} label={tag} />
                ))}
            </Stack>
        );
    }
    else return null;
};


export default TagsField;

