import { Fragment, useState } from 'react';
import {
    SimpleForm, useTranslate,
    Toolbar, SaveButton, useCreate, ReferenceInput,
    useNotify,
    Button, AutocompleteInput,
    useGetIdentity,
} from 'react-admin';
import { SectionField } from '../../components';
import { makeStyles } from '@mui/styles';
import { Cancel, Send } from '@material-ui/icons';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import io from 'socket.io-client';


const BackendURL = process.env.REACT_APP_PROGRESS_URL; // Replace with your backend URL

const useStyles = makeStyles((theme) => ({
    toolbar: {
        display: 'flex',
    },
    spacer: {
        flex: 1,
    },
    buttonMarginLeft: {
        marginLeft: 10
    },
    inputPaddingRight: {
        paddingRight: 'unset'
    },
    flexBox: {
        width: 'auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    inputMarginLeft: {
        marginLeft: 40
    },
    color: {
        color: theme.palette.primary.main,
        backgroundColor: 'white',
        border: theme.palette.primary.main
    },
}));

const Validate = (values) => {
    const errors = {};
    if (!values.trainingTemplate) errors.trainingTemplate = 'ra.validation.required';
    return errors;
};

const Deploy = (props) => {
    const [create] = useCreate();
    const notify = useNotify();
    const classes = useStyles();
    const translate = useTranslate();
    const [showDialog, setShowDialog] = useState(false);
    const [deploymentInitiated, setDeploymentInitiated] = useState(false);
    const [deployments, setDeployments] = useState({}); // Store deployment state for each tab
    const [showProgressDialog, setShowProgressDialog] = useState(false); // New dialog state
    const [progressData, setProgressData] = useState({ progress: 0, statusText: '', status: '' });
    const trainingTemplateId = props.trainingTemplateId;
    const trainingTemplateName = props.trainingTemplateName;
    const filterToQuery = searchText => ({ name: `${searchText}` });

    const workspaceId = props.workspaceId;

    const { data: identity, isLoading, error } = useGetIdentity();

    const { id: userId } = identity;

    let postDefaultValue;
    if (props.trainingTemplateId) {
        //labelButton = 'app.instanceTemplates.addToTrainingTemplate.button';
        postDefaultValue = () => ({
            trainingTemplate: props.trainingTemplateId,
        });
    };

    /*const socket = io(BackendURL);
    console.log(socket, 'socket');*/

    const CreateToolbar = () => {

        return (
            <Toolbar className={classes.toolbar}>
                <Button color='primary' label="ra.action.cancel" size="medium" onClick={handleCloseClick} className={classes.color}>
                    <Cancel />
                </Button>
                <SaveButton label={translate('app.buttons.deploy')} className={classes.buttonMarginLeft} />
            </Toolbar>
        );
    };

    const handleClick = () => {
        setShowDialog(true);
        //handleShowProgressDialog();
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const trainingTemplateDeploy = (data) => {

        const dataToSend = {
            "room": workspaceId,
            "trainingTemplate": data.trainingTemplate,
        };

        deployProgress();

        // Open the progress dialog initially
        setShowProgressDialog(true);
        setDeploymentInitiated(true);
        create(`trainingTemplates/deployInWorkspace`, { data: dataToSend }, {
            onSuccess: response => {

                if (!response) {
                    notify('ra.notification.created', 'info');

                } else {
                    notify(translate('app.trainingTemplates.deploy.successMessage'), { type: 'success' });
                };
            },
            onError: error => {
                if (error.message) {
                    notify(`${translate('app.trainingTemplates.deploy.errorMessage')}: ${error.message}`, { type: 'error' });
                }
                else {
                    notify(translate('app.trainingTemplates.deploy.errorMessage'), { type: 'error' });
                }
            }
        }
        );
        handleCloseClick();
    };

    const deployProgress = () => {
        if (!userId) {
            // If userId is not available, return early
            return;
        }

        const socket = io(BackendURL, {
            query: { userId }, // Pass the userId as a query parameter
        });

        socket.on('connect', () => {
            console.log('WebSocket connection established.');
        });

        socket.on('disconnect', () => {
            console.log('WebSocket connection closed.');
        });

        socket.emit('joinRoom', userId);

        // Event handler for 'deploymentProgress' event from the backend
        socket.on('deploymentProgress', ({ userId, progress, statusText, trainingId, status }) => {
            // Update the progress and statusText in the component state
            setProgressData({ progress, statusText, status });

            // Open the progress dialog when the event is received
            setShowProgressDialog(true);


            const updatedDeployments = { ...deployments };
            updatedDeployments[userId] = { progress, statusText, status };
            setDeployments(updatedDeployments);
        });
    };

    if (isLoading) return <div>{translate('app.LoadingMessage')}</div>;


    return (
        <Fragment>
            <Button label={translate('app.trainingTemplates.deploy.buttonInWorkspace')} onClick={handleClick}><Send /></Button>

            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label={translate('app.trainingTemplates.deploy.title')}
            >
                <DialogTitle> <SectionField label={translate('app.trainingTemplates.deploy.title')} icon={Send} /> </DialogTitle>
                <DialogContent>
                    <SimpleForm validate={Validate} toolbar={<CreateToolbar />} onSubmit={trainingTemplateDeploy}
                        redirect="list" defaultValues={postDefaultValue}>

                        {trainingTemplateId ?
                            <AutocompleteInput source='trainingTemplate'
                                label={translate('app.trainingTemplates.title')} choices={[
                                    { id: trainingTemplateId, name: trainingTemplateName },
                                ]} optionText="name" filterToQuery={filterToQuery} />
                            :
                            <ReferenceInput
                                source="trainingTemplate"
                                reference="trainingTemplates"
                                label={translate('app.trainingTemplates.title')}
                            >
                                <AutocompleteInput optionText="name" filterToQuery={filterToQuery} />

                            </ReferenceInput>
                        }
                    </SimpleForm>
                </DialogContent>
            </Dialog>


            {/* Render your progress bar or other UI elements */}
            {/* New Dialog to show progress and status */}
            {Object.keys(deployments).map((userId) => (
                <Dialog
                    key={userId}
                    fullWidth
                    open={true} // Show dialog based on deployment state
                    onClose={() => {
                        const updatedDeployments = { ...deployments };
                        delete updatedDeployments[userId];
                        setDeployments(updatedDeployments);
                    }}
                    aria-label="Deployment Progress"
                >
                    <DialogTitle>
                        <SectionField label='Deployment Progress' icon={Send} />
                    </DialogTitle>
                    <DialogContent>
                        <h1>Progress: {deployments[userId].progress}%</h1>
                        <h1>Status: {deployments[userId].status}</h1>
                        <h1>Step: {deployments[userId].statusText}</h1>
                    </DialogContent>
                </Dialog>
            ))}
        </Fragment>
    )
}

export default Deploy;
