import { Chip } from "@mui/material";
import { Stack } from '@mui/material';
import { useRecordContext } from 'react-admin';

const RolesField = () => {
  const record = useRecordContext();
  
  if (record) {
    const roles = record.roles;
    return (
      <Stack direction="row" spacing={1}>
        {roles.map(role => (
          <Chip color="primary" key={role} label={role} />
        ))}
      </Stack>

    );
  }
  else return null;
};


export default RolesField;