import { useTranslate, useLogin, useNotify, useSafeSetState, useRedirect } from 'ra-core';
import { Field, Form } from 'react-final-form';
import {
  Button, CardActions, CircularProgress, TextField,
  Typography, Grid
} from '@material-ui/core';
import { makeStyles } from '@mui/styles';
import { Lock } from '@material-ui/icons';
import { useState } from 'react';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  form: {
    padding: '0 1em 1em 1em',
    margin: theme.spacing(1),
    // add responsive behavior
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.5),
    },
  },
  input: {
    marginTop: theme.spacing(2),
    width: '100%', // make input full width
    // Override the input base styles
    '& .MuiInputBase-input': {
      height: 'auto', // Adjust the height as needed
      padding: theme.spacing(1), // Add padding to ensure text is centered
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  submitButton: {
    fontSize: '0.80rem',
  },
  resetPasswordButton: {
    marginTop: 20,
    fontSize: '0.75rem',
  },
  resetPasswordIcon: {
    marginTop: -4
  },
  title: {
    '&.MuiTypography-h6': {
      fontSize: '1.5rem',
    },
  }
}),
  { name: 'RaLoginForm' }
);

const Input = ({
  meta: { touched, error }, // eslint-disable-line react/prop-types
  input: inputProps, // eslint-disable-line react/prop-types
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

const CustomLoginForm = (props) => {
  const { redirectTo } = props;
  const [loading, setLoading] = useSafeSetState(false);
  const login = useLogin();
  const translate = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();
  const classes = useStyles(props);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  const resetPassword = () => {
    redirect('/resetpassword');
  };

  const submit = values => {
    setLoading(true);
    login(values, redirectTo)
      .then(() => {
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        if (error.message === "Password reset required") {
          console.log(error.message, 'error');

          redirect("/resetpassword");
        };
        notify(
          typeof error === 'string'
            ? error
            : typeof error === 'undefined' || !error.message
              ? 'ra.auth.sign_in_error'
              : error.message,
          'warning',
          {
            _:
              typeof error === 'string'
                ? error
                : error && error.message
                  ? error.message
                  : undefined,
          }
        );
      });
  };

  return (
    <Form
      onSubmit={submit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Typography align='center' color='primary' variant='h6' className={classes.title}>Remote Labs</Typography>
          <div className={classes.form}>
            <div className={classes.input}>
              <Field
                autoFocus
                id="username"
                name="username"
                component={Input}
                label={translate('ra.auth.username')}
                disabled={loading}
              //validate={required}
              />
            </div>
            <div className={classes.input}>
              <Field
                id="password"
                name="password"
                component={Input}
                label={translate('ra.auth.password')}
                type={showPassword ? 'text' : 'password'}
                disabled={loading}
                autoComplete="current-password"
                //validate={required}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <CardActions>
            <Grid container>
              <Grid item xs={12}>
                <Button fullWidth={true} variant="contained" type="submit" color="primary" disabled={loading} className={classes.submitButton}>
                  {loading && (
                    <CircularProgress
                      className={classes.icon}
                      size={18}
                      thickness={2}
                    />
                  )}
                  {translate('ra.auth.sign_in')}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button fullWidth={true} disabled={loading} className={classes.resetPasswordButton} color='primary' size='small'
                  startIcon={<Lock className={classes.resetPasswordIcon} />} onClick={() => { resetPassword() }}>Reset my password</Button>
              </Grid>
            </Grid>
          </CardActions>
        </form>
      )}
    />


  );
};

export default CustomLoginForm;
