import { AppBar, Logout, MenuItemLink, UserMenu } from 'react-admin';
import { Typography, } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import ExitIcon from '@mui/icons-material/PowerSettingsNew';
import { Person } from '@mui/icons-material';

const useStyles = makeStyles((theme) => {
  return ({
    title: {
      flex: 1
    },
    logo: {
      height: 25
    },
    spacer: {

    },
  });
});

const CustomAppBar = (props) => {
  const classes = useStyles(props);
  const [switchUser, setSwitchUser] = useState(false);
  const tokenAdmin = localStorage.getItem("tokenAdmin");

  useEffect(() => {
    if (tokenAdmin) {
      setSwitchUser(true);
    }
  })

  const BackToAdminAccount = () => {

    const handleClick = () => {
      localStorage.setItem("token", tokenAdmin);
      localStorage.removeItem("tokenAdmin");
      setSwitchUser(false)
      window.location.href = "/";
    }
    return (
      <button onClick={handleClick}>Back to your account</button>
    )
  }

  const theme = useTheme();

  const MyLogoutButton = props => <Logout {...props} icon={<ExitIcon />} />;

  const MyUserMenu = () =>
    <UserMenu>
      <MenuItemLink
        to="/myprofile"
        primaryText="My Profile"
        leftIcon={<Person />}
      />
      <MyLogoutButton />
    </UserMenu>
    ;

  return (
    <AppBar sx={{ bgcolor: theme.palette.primary.main }} userMenu={<MyUserMenu />} {...props}>
      <Box component="img" src='/logo-white.png' alt='Alcatel-Lucent Enterprise' sx={{ height: 35, width: 150 }} />
      <Typography variant='h5' align='center' color='inherit' className={classes.title}>Remote Labs</Typography>
      <span className={classes.spacer} />
      {switchUser ? <BackToAdminAccount /> : ""}
    </AppBar>
  )
};

export default CustomAppBar;