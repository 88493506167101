import { Loading } from 'react-admin';
import Loadable from 'react-loadable';

const VmList = Loadable({ loader: () => import('./vmList'), loading: Loading });
const VmCreate = Loadable({ loader: () => import('./vmCreate'), loading: Loading });
const VmShow = Loadable({ loader: () => import('./vmShow'), loading: Loading });
const VmEdit = Loadable({ loader: () => import('./vmEdit'), loading: Loading });

export default { 
  list: VmList,
  create: VmCreate,
  show: VmShow,
  edit: VmEdit
};