const handleError = (error, notify) => {

    if (error.response && error.response.data && error.response.data.message) {
        {
            error.response.data.detail ? notify(`${error.response.data.message} : ${error.response.data.detail.errorMessage}`, { type: 'error' }) :
                notify(`${error.response.data.message}`, { type: 'error' })
        };
    }
    else throw new Error(error);
}

export default handleError;