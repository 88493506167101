import {
    Show,
    useTranslate,
    useGetOne,
    TopToolbar,
    DeleteWithConfirmButton,
    EditButton,
    useRecordContext
}
    from 'react-admin';

import BasicBreadcrumbs from '../../components/breadcrumb';
import { useParams } from 'react-router-dom';
import { CustomInstanceShow } from '../../custom';

const DeleteButton = (props) => {
    const translate = useTranslate();

    const title = `${translate('app.vms.delete.title')} ${props.record.name}`;

    //translateOptions={{ id: " ", name: record.record.name }}
    return (
        <DeleteWithConfirmButton confirmContent={translate('app.vms.delete.confirmMessage')}
            confirmTitle={title} />
    )
};

const Actions = (props) => {
    const record = useRecordContext();
    return (
        <TopToolbar>
            <EditButton />
            <DeleteButton record={record} />
        </TopToolbar>
    );
};

const VmShowInWorkspace = () => {
    const translate = useTranslate();
    const { id, vmId } = useParams();
    const { data: vmData, isLoading} = useGetOne('vms', { id: vmId });
    const { data: workspaceData, isLoading: isLoading2 } = useGetOne('workspaces', { id });
    if (isLoading) return <div>{translate('app.LoadingMessage')}</div>;
    if (isLoading2) return <div>{translate('app.LoadingMessage')}</div>;


    return (
        <>
            <BasicBreadcrumbs name={workspaceData.name} name2={vmData.name} />
            <Show actions={<Actions />} id={vmId}
                resource="vms"
                redirect={false}>
                <CustomInstanceShow record={vmData} />
            </Show>
        </>
    );
};

const VmShowInMyWorkspace = (props) => {
    const translate = useTranslate();
    const { id, vmId } = useParams();
    const { data: vmData, isLoading, error } = useGetOne('vms', { id: vmId });
    if (isLoading) return <div>{translate('app.LoadingMessage')}</div>;


    return (
        <>
            <BasicBreadcrumbs name={vmData.name} />
            <Show actions={<Actions />} id={vmId}
                resource="vms"
                redirect={false}>
                <CustomInstanceShow record={vmData} />
            </Show>
        </>
    );
};

export { VmShowInWorkspace, VmShowInMyWorkspace };