import { Typography, Box, SvgIcon } from '@material-ui/core';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => {
  return ({
    viewTitleBox: {
      marginTop: 10,
      marginLeft: 10,
      color: theme.palette.primary.main,
      display: 'flex',
      alignItems: 'center',
    },
    viewTitleText: {
     
    },
    viewTitleIcon: {
      paddingRight: 10,
    },
  })
});

const ViewTitle = (props) => {
  const classes = useStyles(props);
  if (props.icon) return <Box className={classes.viewTitleBox}><SvgIcon fontSize='large' component={props.icon} className={classes.viewTitleIcon} /><Typography variant='h5' className={classes.viewTitleText}>{props.label}</Typography></Box>
  else return <Box className={classes.viewTitleBox}><Typography variant='h5' className={classes.viewTitleText}>{props.label}</Typography></Box>
};

export default ViewTitle;