import {
  Admin, Resource, fetchUtils, Loading,
  CustomRoutes, Authenticated,
  useTranslate,
  usePermissions
} from 'react-admin';
import Loadable from 'react-loadable';
import { AuthProvider, DataProvider, i18nProvider } from './providers';
import {
  CustomAppLayout, CustomAppRoutes, CustomTheme,
  CustomLogin, LatestTheme
} from './custom';
import {
  PeopleAlt, Bookmark, PhotoLibrary, LibraryBooks, Cloud,
  Public, CastForEducation, Apps, PhotoCamera,
  Class, SupervisorAccount, FlipToFront, School, Storage,
} from '@material-ui/icons';

import EmptyList from './views/emptyList';
import users from './views/users';
import images from './views/images';
import workspaces from './views/workspaces';
import MyWorkspace from './views/workspaces/myWorkspace';
import pods from './views/pods';
import classrooms from './views/classrooms'
import networks from './views/networks';
import routers from './views/routers';
import vms from './views/vms';
import trainingTemplates from './views/trainingTemplates';
import instanceTemplates from './views/instanceTemplates';
import MyClassroom from './views/classrooms/myClassroom';
import volumes from './views/volumes';
import flavors from './views/flavors';
import { createGenerateClassName, ThemeProvider as MuiThemeProvider, StylesProvider } from '@material-ui/core/styles';
import { ThemeProvider } from '@emotion/react';
import MyPod from './views/pods/myPod';
import trainings from './views/trainings';
import { ViewCompactAlt, UploadFile, Task } from '@mui/icons-material';
import { Route } from 'react-router-dom';
import ResetPassword from './views/resetPassword';
import MyProfile from './views/users/myProfile';
import { VmShowInPod, VmShowInMyPod } from './views/pods/vmShowInPod';
import { VmShowInWorkspace, VmShowInMyWorkspace } from './views/workspaces/vmShowInWorkspace';
import files from './views/files';
import ResetPasswordByLink from './views/resetPasswordByLink';
import tasks from './views/tasks';
import VmShowGeneric from './views/vms/vmShowGeneric';
import PodShowGeneric from './views/pods/podShowGeneric';
import TrainingTemplateClone from './views/trainingTemplates/trainingTemplateClone';
import { Navigate } from 'react-router-dom';

const Dashboard = Loadable({ loader: () => import('./dashboard'), loading: Loading });

const httpClient = (url, options = {}) => {
  if (!options.headers) options.headers = new Headers({ Accept: 'application/json' });
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const RlabDataProvider = DataProvider('/api/v1', httpClient);

const generateClassName = createGenerateClassName({
  productionPrefix: 'myApp',  // Custom prefix to avoid conflicts and .jss on build
});

const App = () => {
  const translate = useTranslate();

  return (
    <StylesProvider injectFirst generateClassName={generateClassName}>
      <MuiThemeProvider theme={LatestTheme}>

        <ThemeProvider theme={LatestTheme}>

          <Admin
            dataProvider={RlabDataProvider}
            authProvider={AuthProvider}
            i18nProvider={i18nProvider}
            theme={CustomTheme}
            loginPage={CustomLogin}
            layout={CustomAppLayout}
            disableTelemetry>

            <CustomRoutes>
              <Route path="/" element={<Authenticated> <Dashboard /> </Authenticated>} />
            </CustomRoutes>

            <CustomRoutes noLayout>
              <Route path="/resetpassword" element={<ResetPassword />} />
              <Route path="/resetpassword/:userId/:token" element={<ResetPasswordByLink />} />

            </CustomRoutes>

            <CustomRoutes>
              <Route path="/myprofile" element={<Authenticated> <MyProfile /> </Authenticated>} />
            </CustomRoutes>

            <CustomRoutes>
              <Route path="/trainingTemplates/:id/clone" element={<Authenticated>  <TrainingTemplateClone /> </Authenticated>} />
            </CustomRoutes>

            <Resource name='users' options={{ roles: ['admin'] }} icon={PeopleAlt} {...users} />

            <Resource name='myworkspace' options={{ label: translate("resources.myworkspace.name"), roles: ['admin', 'designer'] }} icon={Bookmark} list={MyWorkspace}>
              <Route path="userPod/:vmId" element={<Authenticated> <VmShowInMyWorkspace /> </Authenticated>} />
              <Route path="commonPod/:vmId" element={<Authenticated> <VmShowInMyWorkspace /> </Authenticated>} />
            </Resource>

            <Resource name='myclassroom' options={{ label: translate("resources.myclassroom.name"), roles: ['trainer'] }} icon={CastForEducation} list={MyClassroom} >
              <Route path="pod/:podId" element={<Authenticated> <PodShowGeneric resourceType="myclassroom" /> </Authenticated>} />
              <Route path="pod/:podId/instances/:vmId" element={<Authenticated> <VmShowGeneric resourceType="myclassroom" /> </Authenticated>} />
            </Resource>

            <Resource name='mypod' options={{ label: translate("resources.mypod.name"), roles: ['trainee'] }} icon={Class} list={MyPod}>
              <Route path="Instances/:vmId" element={<Authenticated> <VmShowInMyPod /> </Authenticated>} />
            </Resource>

            <Resource name='Design' options={{ roles: ['admin', 'designer'], isMenuParent: true }} icon={ViewCompactAlt} list={EmptyList} />

            <Resource name='instanceTemplates' options={{ label: 'Instance Templates', roles: ['admin', 'designer'], menuParent: 'Design' }} icon={PhotoCamera} {...instanceTemplates} />

            <Resource name='trainingTemplates' options={{ label: 'Training templates', roles: ['admin', 'designer'], menuParent: 'Design' }} {...trainingTemplates} />

            <Resource name='images' options={{ roles: ['admin', 'designer'], menuParent: 'Design', filter: { type: 'base' } }} icon={PhotoLibrary} {...images} />

            <Resource name='admin' options={{ roles: ['admin'], isMenuParent: true }} icon={SupervisorAccount} list={EmptyList} />

            <Resource name='pods' options={{ roles: ['admin'], menuParent: 'admin' }}  {...pods}>
              <Route path=":id/show/Instances/:vmId" element={<Authenticated> <VmShowInPod /> </Authenticated>} />
            </Resource>

            <Resource name='networks' options={{ roles: ['admin'], menuParent: 'admin' }} icon={Cloud} {...networks} />

            <Resource name='routers' options={{ roles: ['admin'], menuParent: 'admin' }} icon={Public} {...routers} />

            <Resource name='vms' options={{ roles: ['admin'], label: 'Instances', menuParent: 'admin' }} icon={Apps} {...vms} />

            <Resource name='flavors' options={{ roles: ['admin'], menuParent: 'admin' }} icon={FlipToFront} {...flavors} />

            <Resource name='workspaces' options={{ roles: ['admin'], menuParent: 'admin' }} icon={LibraryBooks} {...workspaces}>
              <Route path=":id/show/commonPod/:vmId" element={<Authenticated> <VmShowInWorkspace /> </Authenticated>} />
              <Route path=":id/show/userPod/:vmId" element={<Authenticated> <VmShowInWorkspace /> </Authenticated>} />

            </Resource>

            <Resource name='classrooms' options={{ roles: ['admin'], menuParent: 'admin' }} icon={CastForEducation} {...classrooms}>
              <Route path=":id/show/pod/:podId" element={<Authenticated> <PodShowGeneric resourceType="classrooms" /> </Authenticated>} />
              <Route path=":id/show/pod/:podId/instances/:vmId" element={<Authenticated> <VmShowGeneric resourceType="classrooms" /> </Authenticated>} />
            </Resource>

            <Resource name='trainings' options={{ roles: ['admin'], menuParent: 'admin' }} icon={School} {...trainings} >
              <Route path="/trainings/:id/logs" element={<Authenticated> <VmShowInWorkspace /> </Authenticated>} />
              <Route path=":id/show/pod/:podId" element={<Authenticated> <PodShowGeneric resourceType="trainings" /> </Authenticated>} />
              <Route path=":id/show/pod/:podId/instances/:vmId" element={<Authenticated> <VmShowGeneric resourceType="trainings" /> </Authenticated>} />
            </Resource>

            <Resource name='volumes' options={{ roles: ['admin'], menuParent: 'admin' }} icon={Storage} {...volumes} />

            <Resource name='files' options={{ roles: ['admin'], menuParent: 'admin' }} icon={UploadFile} {...files} />

            <Resource name='tasks' options={{ roles: ['admin'], menuParent: 'admin' }} icon={Task} {...tasks} />

          </Admin>

        </ThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  );
}

export default App;
