import {
    Show,
    useTranslate,
    useGetOne,
    DeleteWithConfirmButton,
    TopToolbar
}
    from 'react-admin';
import BasicBreadcrumbs from '../../components/breadcrumb';
import { useParams } from 'react-router-dom';
import { CustomInstanceShow } from '../../custom';


const Actions = (props) => {
    return (
        <TopToolbar>
            <DeleteWithConfirmButton />
        </TopToolbar>
    )
};

const VmShowInPod = (props) => {
    const translate = useTranslate();
    const { id, vmId } = useParams();
    const { data : vmData, isLoading, error } = useGetOne('vms', { id: vmId });
    const { data: podData, isLoading: isLoading2, error: error2 } = useGetOne('pods', { id });

    if (isLoading) return <div>{translate('app.LoadingMessage')}</div>;
    if (isLoading2) return <div>{translate('app.LoadingMessage')}</div>;
    
    if (error) return <div>{translate('app.ErrorMessage')}</div>;
    if (error2) return <div>{translate('app.ErrorMessage')}</div>;

    return (
        <>
            <BasicBreadcrumbs name={podData.name} name2={vmData.name} />
            <Show actions={<Actions />} id={vmId}
                resource="vms"
                redirect={false}>
                <CustomInstanceShow record={vmData} />
            </Show>
        </>
    );
};

const VmShowInMyPod = (props) => {
    const translate = useTranslate();
    const { vmId } = useParams();
    const { data: vmData, isLoading, error } = useGetOne('vms', { id: vmId });
    if (isLoading) return <div>{translate('app.LoadingMessage')}</div>;
    if (error) return <div>{translate('app.ErrorMessage')}</div>;

    return (
        <>
            <BasicBreadcrumbs name={vmData.name} />
            <Show actions={<Actions />} id={vmId}
                resource="vms"
                redirect={false}>
                <CustomInstanceShow record={vmData} />
            </Show>
        </>
    );
};


export { VmShowInPod, VmShowInMyPod };