import { defaultTheme } from 'react-admin';
import merge from 'lodash/merge';
import { createTheme } from '@material-ui/core/styles'

const LatestTheme = createTheme(merge({}, defaultTheme, {
  palette: {
    type: 'light',
    primary: {
      main: '#6b489d',
    }
  },
  sidebar: {
    closedWidth: 55,
    width: 200,
  },
  typography: {
    fontSize: 12
  },
  components: {},
  

}));


export default LatestTheme;