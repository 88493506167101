import React, { useState } from 'react';
import { ArrayInput, SimpleFormIterator, NumberInput, AutocompleteInput, useTranslate } from 'react-admin';
import { IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { makeStyles } from '@material-ui/core/styles';
import  SectionField from "./sectionField";

const useStyles = makeStyles((theme) => ({
    helpIcon: {
        marginLeft: theme.spacing(1),
    },
}));

const DiskBusHelpModal = () => {
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const translate = useTranslate();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            {/* Help Button */}
            <IconButton className={classes.helpIcon} onClick={handleClickOpen}>
                <HelpOutlineIcon />
            </IconButton>

            {/* Modal Dialog with detailed information */}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle><SectionField label={translate('app.help.diskBusHelp')} icon={HelpOutlineIcon} /></DialogTitle>
                <DialogContent>
                    <p><strong>Windows:</strong> Use <code>virtio</code> for optimal performance on Windows-based virtual machines.</p>
                    <p><strong>Linux:</strong> For most Linux distributions, <code>virtio</code> is also recommended, but you can use <code>scsi</code> if you need compatibility with older kernels.</p>
                    <p><strong>OXE:</strong> Use <code>ide</code> for compatibility.</p>
                    <p><strong>Other Operating Systems:</strong> Depending on your operating system, <code>ide</code> may be necessary for older OS versions, but <code>virtio</code> and <code>scsi</code> provide better performance.</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DiskBusHelpModal;