import { Stack } from '@mui/material';
import { useRecordContext } from 'react-admin';
import  convertByte from '../utils/convertByte';

const NewArrayDiskField = () => {
  const record = useRecordContext();

  if (record) {
    const disks = record.disks;
    return (
      <Stack direction="row" spacing={1}>
        {disks.map(disk => (
          <span key={disk.osSize}> {convertByte(disk.osSize)}</span>
))}
      </Stack>

    );
  }
  else return null;
};


export default NewArrayDiskField;