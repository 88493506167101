import { Route } from 'react-router-dom';
import ResetPassword from '../views/resetPassword';

//import MyWorkspace from '../views/workspaces/myWorkspace';

const appRoutes = [
  <Route exact path='/resetpassword' component={(props) => <ResetPassword {...props}/>} noLayout/>,
];

export default appRoutes;


/*
  //<Route exact path='/profile' component={Profile} />,
  //<Route exact path='/about' component={About} />,
  <Route path='/myworkspace' component={MyWorkspace} />
*/