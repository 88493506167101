import { useState, useEffect } from 'react';
import {
  useDataProvider, Error, Loading, useTranslate
} from 'react-admin';
import WorkspaceShow from './workspaceShow';
import decodeJwt from 'jwt-decode';

const MyWorkspace = () => {
  const { sub } = decodeJwt(localStorage.getItem('token'));
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const [workspaceId, setWorkspaceId] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    dataProvider.getOne('users', { id: sub.id })
      .then(({ data }) => {
        if (data && data.workspace) {
          setWorkspaceId(data.workspace);
          setLoading(false);
        }
        else {
          setError(translate('app.workspaces.myworkspace.noWorkspaceMessage'));
          setLoading(false);
        }
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      })
  }, []);

  if (loading) return <Loading />;
  if (error) return <Error error={error.message ? error.message : error} />;
  if (!workspaceId) return null;


  return (
    <div>
      <WorkspaceShow id={workspaceId} resource="workspaces" myWorkspace="true"/>
    </div>
  )

};

export default MyWorkspace;
