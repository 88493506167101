import { ChevronLeft } from '@material-ui/icons';
import { useNavigate } from 'react-router'
import { useTranslate } from 'react-admin';
import { Button } from '@mui/material';

const BackButton = () => {
  const translate = useTranslate();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1);
  };

  return (
    <Button
      onClick={handleClick}  variant="outlined"><ChevronLeft /> {translate('app.buttons.back')} </Button>
  );
};

export default BackButton