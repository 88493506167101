import { useState, useEffect } from 'react';
import {
  useDataProvider, Loading, useTranslate, Button
} from 'react-admin';
import ClassroomShow from './classroomShow';
import decodeJwt from 'jwt-decode';
import { Description } from '@material-ui/icons';
import { SectionField } from '../../components';
import ComponentTitle from '../../components/componentTitle';
import { CastForEducation } from '@mui/icons-material';
import BasicBreadcrumbs from '../../components/breadcrumb';
import { Box, Card, CardContent, Typography } from '@material-ui/core';
import { ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material';


const NoClassroomMessage = () => {
  const translate = useTranslate();

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
      <Card sx={{ maxWidth: 500, padding: 3, textAlign: 'center' }}>
        <CardContent>
          <ErrorOutlineIcon color="error" style={{ fontSize: 50, marginBottom: 10 }} />
          <Typography variant="h6" color="textSecondary" gutterBottom>
            {translate('app.classrooms.noClassroomTitle')}
          </Typography>
          <Typography variant="body1">
            {translate('app.classrooms.noClassroomMessage')}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

const MyClassroom = () => {
  const { sub } = decodeJwt(localStorage.getItem('token'));
  const dataProvider = useDataProvider();
  const [classroomsId, setClassroomsId] = useState([]);
  const [classrooms, setClassrooms] = useState([]);
  const [classroomSelected, setClassroomSelected] = useState(null);
  const [loading, setLoading] = useState(true);
  const [classroomsLoading, setClassroomsLoading] = useState(true);
  const [error, setError] = useState(null);
  const translate = useTranslate();

  useEffect(() => {
    // Fetch user and their classrooms
    dataProvider.getOne('users', { id: sub.id })
      .then(({ data }) => {
        if (data && data.classroom.length > 0) {
          setClassroomsId(data.classroom);
        } else {
          setClassroomsId([]); // Explicitly set as empty array for clarity
        }
      })
      .catch(error => {
        setError(error.message || translate('app.classrooms.myclassroom.genericError'));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [sub.id, dataProvider, translate]);

  useEffect(() => {
    // Fetch classrooms if there are multiple IDs
    if (classroomsId.length > 1) {
      setClassroomsLoading(true);
      Promise.all(
        classroomsId.map(id =>
          dataProvider.getOne('classrooms', { id })
            .then(({ data }) => data ? { id, name: data.name } : null)
            .catch(error => {
              setError(error.message || translate('app.classrooms.myclassroom.fetchError'));
              return null;
            })
        )
      )
        .then(results => {
          setClassrooms(results.filter(Boolean)); // Filter out any failed fetches
        })
        .finally(() => {
          setClassroomsLoading(false);
        });
    }
  }, [classroomsId, dataProvider, translate]);

  if (loading) return <Loading />;
  if (error) return <Typography color="error">{error.message || 'An unexpected error occurred'}</Typography>;

  // Show NoClassroomMessage if no classrooms are assigned
  if (classroomsId.length === 0) return <NoClassroomMessage />;

  return (
    <div>
      <ComponentTitle label={translate('resources.myclassroom.name')} icon={CastForEducation} />
      <BasicBreadcrumbs />

      {/* Render classroom selection if there are multiple classrooms */}
      {classroomsId.length > 1 && classroomsLoading ? (
        <Loading />
      ) : classroomsId.length > 1 ? (
        <>
          <SectionField label={translate('app.classrooms.title')} icon={Description} />
          {classrooms.map((room) => (
            <Button
              key={room.id}
              size='medium'
              onClick={() => setClassroomSelected(room.id)}
              sx={{ border: '1px solid #6b489d', margin: '8px' }}
            >
              {room.name}
            </Button>
          ))}
        </>
      ) : (
        <ClassroomShow id={classroomsId[0]} resource="classrooms" myclassroom='true' />
      )}

      {/* Render selected classroom if a specific classroom has been chosen */}
      {classroomSelected && (
        <ClassroomShow id={classroomSelected} resource="classrooms" />
      )}
    </div>
  );
};


export default MyClassroom;