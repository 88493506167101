import React, { useState } from 'react';
import { Label, ViewList } from '@material-ui/icons';
//import { useMediaQuery, makeStyles } from '@material-ui/core';
import { useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  MenuItemLink, useResourceDefinitions, useSidebarState,
  useTranslate, DashboardMenuItem, usePermissions
} from 'react-admin';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CustomMenuItem from './customMenuItem';

const useStyles = makeStyles(
  theme => ({
    main: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      marginTop: '0.5em',
      [theme.breakpoints.only('xs')]: {
        marginTop: 0,
      },
      [theme.breakpoints.up('md')]: {
        marginTop: '1.5em',
      },
    },
  }),
  { name: 'CustomMenu' }
);

const CustomMenu = (props) => {
  const {
    className,
    dense,
    hasDashboard,
    onMenuClick,
    logout,
    ...rest
  } = props;

  const { permissions } = usePermissions();

  const classes = useStyles(props);
  const translate = useTranslate();
  const [open] = useSidebarState();
  const resourcesDefinitions = useResourceDefinitions();
  const resources = Object.keys(resourcesDefinitions).map(name => resourcesDefinitions[name]);
  //const resources = useSelector(getResources, shallowEqual);
  const hasList = (resource) => (resource.hasList);

  const handleToggle = (parent) => {
    setState(state => ({ [parent]: !state[parent] }));
  };

  // eslint-disable-next-line
  const isXSmall = useMediaQuery((theme) =>
    theme.breakpoints.down('xs')
  );

  const hasChilds = (resource) => {
    return resources.filter((r) => isChildOfParent(r, resource) && hasList(r)).length > 0 ? true : false;
  };

  const hasRights = (resource) => {
    if (!permissions) return false;
    if (resource.options && resource.options.roles && resource.options.roles.length > 0) {
      let display = false;
      resource.options.roles.forEach(role => {
        if (permissions.includes(role)) display = true;
      });
      return display;
    }
    else return true;
  };

  const isParent = (resource) => (
    resource.options &&
    resource.options.hasOwnProperty('isMenuParent') &&
    resource.options.isMenuParent
  );

  const isOrphan = (resource) => (
    resource.options &&
    !resource.options.hasOwnProperty('menuParent') &&
    !resource.options.hasOwnProperty('isMenuParent')
  );

  const isChildOfParent = (resource, parentResource) => (
    resource.options &&
    resource.options.hasOwnProperty('menuParent') &&
    resource.options.menuParent === parentResource.name
  );



  const getResourceName = (slug) => {
    if (!slug) return;
    var words = slug.toString().split('_');
    for (var i = 0; i < words.length; i++) {
      var word = words[i];
      words[i] = word.charAt(0).toUpperCase() + word.slice(1);
    }
    return words.join(' ');
  }

  const getPrimaryTextForResource = (resource) => {
    let resourcename = '';
    if (resource.options && resource.options.label)
      resourcename = resource.options.label;
    else if (resource.name) {
      resourcename = translate(`resources.${resource.name}.name`);
      if (resourcename.startsWith('resources.'))
        resourcename = getResourceName(resource.name);
    }
    return resourcename;
  }

  const MenuItem = (resource) => {
    let to = {};
    if (resource.options.filter) {
      to = {
        pathname: resource.options.path ? `/${resource.options.path}` : `/${resource.name}`,
        search: `filter=${JSON.stringify(resource.options.filter)}`,
      }
    }
    else to = resource.options.path ? `/${resource.options.path}` : `/${resource.name}`

    return (
      <MenuItemLink
        key={resource.name}
        to={to}
        primaryText={getPrimaryTextForResource(resource)}
        leftIcon={
          resource.icon
            ? <resource.icon />
            : <ViewList />
        }
        onClick={onMenuClick}
        dense={dense}
        sidebarIsOpen={open}
      />
    )
  };

  const mapParentStack = (parentResource) => (
    <CustomMenuItem
      key={parentResource.name}
      handleToggle={() => handleToggle(parentResource.name)}
      isOpen={state[parentResource.name] || parentActiveResName === parentResource.name}
      sidebarIsOpen={open}
      name={getPrimaryTextForResource(parentResource)}
      icon={parentResource.icon ? <parentResource.icon /> : <Label />}
      dense={dense}
    >
      {
        // eslint-disable-next-line
        resources
          .filter((resource) => isChildOfParent(resource, parentResource) && hasList(resource))
          .map((childResource) => { return MenuItem(childResource); })
      }
    </CustomMenuItem>
  );

  const mapIndependent = (independentResource) => hasList(independentResource) && MenuItem(independentResource);

  const initialExpansionState = {};
  let parentActiveResName = null;

  resources.forEach(resource => {
    if (isParent(resource)) {
      initialExpansionState[resource.name] = false;
    }
    /*else if (pathname.startsWith(`/${resource.name}`) && resource.options.hasOwnProperty('menuParent')) {
       parentActiveResName = resource.options.menuParent;
     }*/
  });

  const [state, setState] = useState(initialExpansionState);

  const resRenderGroup = [];

  resources.forEach(r => {
    if (isParent(r) && hasChilds(r) && hasRights(r)) resRenderGroup.push(mapParentStack(r));
    if (isOrphan(r) && hasRights(r)) resRenderGroup.push(mapIndependent(r));
  });

  return (
    <div sx={{ bgcolor: 'text.secondary' }}>
      <div className={classnames(classes.main, className)} {...rest}>
        {permissions?.includes('admin') && (<DashboardMenuItem onClick={onMenuClick} dense={dense} sidebarIsOpen={open} />)}
        {resRenderGroup}
      </div>
    </div>
  );
}

CustomMenu.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  dense: PropTypes.bool,
  hasDashboard: PropTypes.bool,
  logout: PropTypes.element,
  onMenuClick: PropTypes.func,
};

CustomMenu.defaultProps = {
  onMenuClick: () => null
};

export default CustomMenu;