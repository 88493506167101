import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslate, useGetOne, Show, TopToolbar, DeleteWithConfirmButton } from 'react-admin';
import BasicBreadcrumbs from '../../components/breadcrumb';
import { CustomInstanceShow } from '../../custom';

const Actions = (props) => {
    return (
        <TopToolbar>
            <DeleteWithConfirmButton />
        </TopToolbar>
    )
};

const VmShowGeneric = ({ resourceType }) => {
    const translate = useTranslate();
    const { id, podId, vmId } = useParams();

    const { data: mainData, isLoading: isLoadingMain, error: errorMain } = useGetOne(resourceType, { id }, { enabled: resourceType !== 'myclassroom' });
    const { data: podData, isLoading: isLoadingPod, error: errorPod } = useGetOne('pods', { id: podId });
    const { data: vmData, isLoading: isLoadingVm, error: errorVm } = useGetOne('vms', { id: vmId });

    if ((isLoadingMain  && resourceType !== 'myclassroom') || isLoadingPod || isLoadingVm) return <div>{translate('app.LoadingMessage')}</div>;
    if ((errorMain  && resourceType !== 'myclassroom') || errorPod || errorVm) return <div>{translate('app.ErrorMessage')}</div>;

    return (
        <>
            <BasicBreadcrumbs name={resourceType !== 'myclassroom' ? mainData?.name : ''} name2={podData.name} name3={vmData.name} />
            <Show actions={<Actions />} id={vmId} resource="vms" redirect={false}>
                <CustomInstanceShow record={vmData} />
            </Show>
        </>
    );
};

export default VmShowGeneric;
