import { Loading } from 'react-admin';
import Loadable from 'react-loadable';

const TrainingTemplateList = Loadable({ loader: () => import('./trainingTemplateList'), loading: Loading });
const TrainingTemplateCreate = Loadable({ loader: () => import('./trainingTemplateCreate'), loading: Loading });
const TrainingTemplateShow = Loadable({ loader: () => import('./trainingTemplateShow'), loading: Loading });
const TrainingTemplateEdit = Loadable({ loader: () => import('./trainingTemplateEdit'), loading: Loading });
const CreateTags = Loadable({loader: () => import('./createTags'), loading: Loading });

export default { 
  list: TrainingTemplateList,
  create: TrainingTemplateCreate,
  show: TrainingTemplateShow,
  edit: TrainingTemplateEdit,
};