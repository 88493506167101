import { Loading } from 'react-admin';
import Loadable from 'react-loadable';

const TaskList = Loadable({ loader: () => import('./taskList'), loading: Loading });
const TaskShow = Loadable({ loader: () => import('./taskShow'), loading: Loading });


export default { 
  list: TaskList,
  show: TaskShow
};